import React, { useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography, Modal, Box, TextField, useMediaQuery } from "@mui/material";
import { useUser } from "src/contexts/user-context";
import toast from "react-hot-toast";
import { api } from "src/services/api";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { DatePicker } from "@mui/x-date-pickers";
import { fetchAddress } from "src/services/viaCep";
import InputMask from 'react-input-mask';

const RegistrationPopup = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();
  const [adminLoginTime, setAdminLoginTime] = useState(null);
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down('desktop'));

  useEffect(() => {

    const verifyAddressAndSetTimer = async () => {
      if (user.userGroupName === "Administrador") {
        
        const response = await api.get(`/v1/customer/verify-address/${user.customer}`);
        const hasAddressRegistration = response.data.customerExist?.zip_code !== undefined && response.data.customerExist?.zip_code !== null;
      
        if (hasAddressRegistration) {
          return;
        }

        if (!adminLoginTime) {
          setAdminLoginTime(new Date().getTime());
        }

        const currentTime = new Date().getTime();
        const oneHour = 60 * 60 * 1000; // 1 hora
        if (adminLoginTime) {
          const timeSinceLogin = currentTime - adminLoginTime;
          const timeRemaining = oneHour - timeSinceLogin;

          if (timeRemaining > 0) {
            setTimeout(() => {
              setShowPopup(true);
            }, timeRemaining);
          } else {
            setShowPopup(true);
          }
        } else {
          setTimeout(() => {
            setShowPopup(true);
          }, oneHour);
        }
      }
    };

    verifyAddressAndSetTimer();

  }, [user.userGroupName, adminLoginTime, user.customer]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleDateChange = (newValue) => {
    setUser({ ...user, birthDate: newValue });
  };

  useEffect(() => {
    const fetchAddressData = async () => {
      if (user.zip?.replace(/\D/g, "").length === 8) {
        setLoading(true);
        const addressData = await fetchAddress(user.zip);
        if (addressData) {
          setUser((prevUser) => ({
            ...prevUser,
            address: addressData.address,
            district: addressData.district,
            city: addressData.city,
            state: addressData.state,
          }));
        }
        setLoading(false);
      }
    };
    fetchAddressData();
  }, [user.zip]);

  const handleUpdateUserAddress = async () => {
    try {
      setLoading(true);
      await api.put(`/v1/customer/update-address/${user.customer}`, {
        zip_code: user.zip,
        address: user.address,
        address_number: user.number,
        address_neighborhood: user.district,
        city: user.city,
        state_id: user.state,
        address_comment: user.complement,
        birthDate: user.birthDate,
        phone1: user.phone1,
      });
      window.localStorage.removeItem("loginTime");
      setShowPopup(false);
      toast.success("Endereço atualizado com sucesso", {
        position: "bottom-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        "Não foi possível editar o endereço do usuário. Por favor, tente novamente",
        { position: "bottom-right" }
      );
    }
  };

  const handleSave = async () => {
    if (
      !user.zip ||
      !user.address ||
      !user.number ||
      !user.district ||
      !user.city ||
      !user.state ||
      !user.phone1
    ) {
      toast.error("Por favor, preencha todos os campos obrigatórios.", {
        position: "bottom-right",
      });
      return;
    }

    await handleUpdateUserAddress();
  };

  return (
    <>
      <Modal
        open={showPopup}
        onClose={() => setShowPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "&:focus-visible": {
              outline: "none",
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
            bgcolor: "background.paper",
            borderRadius: "16px",
            maxHeight: 500,
            width: desktopDown ? "90%" : "50%",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowPopup(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            sx={{ mt: 1 }}
          >
            Estamos quase lá! Ajude-nos a completar seu perfil.
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ 
              mt: 3,
              ml: 1, 
              width: '100%',
              overflow: 'auto',
              maxHeight: 300
            }}
          >
            <TextField
              required
              value={user.zip_code}
              onChange={handleChange}
              label="CEP"
              name="zip"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />

            <TextField
              required
              value={user.address}
              onChange={handleChange}
              label="Endereço"
              name="address"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />

            <TextField
              required
              value={user.number}
              onChange={handleChange}
              label="Número"
              name="number"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />

            <TextField
              required
              value={user.district}
              onChange={handleChange}
              label="Bairro"
              name="district"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />
            <TextField
              value={user.address_comment}
              onChange={handleChange}
              name="complement"
              label="Complemento"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />
            <TextField
              required
              value={user.city}
              onChange={handleChange}
              label="Cidade"
              name="city"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />
            <TextField
              required
              value={user.state}
              onChange={handleChange}
              label="Estado"
              name="state"
              sx={{ mb: 2, width: "100%" }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              variant="outlined"
              disabled={loading}
            />
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <DatePicker
                label="Data de nascimento"
                value={user.birthDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    sx={{ mb: 2, width: "100%" }}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    disabled={loading}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <InputMask
                      mask="(99)99999-9999"
                      value={user.phone1 || ""}
                      disabled={loading}
                      onChange={(e) =>
                        setUser({ ...user, phone1: e.target.value })
                      }
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          type="tel"
                          fullWidth
                          label="Numero de telefone"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          sx={{ mb: 2, width: "100%" }}
                        />
                      )}
                    </InputMask>
            
          </Grid>
          <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 2, ml: "-5px" }}
            >
              <LoadingButton
                disabled={loading}
                loading={loading}
                type="submit"
                onClick={() => handleSave()}
                startIcon={<Save />}
                variant="contained"
              >
                Salvar
              </LoadingButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default RegistrationPopup;
