import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { tourIds } from "src/utils/tour-ids";

export const useTour = () => {
  const driverObj = driver({
    showProgress: true,
    nextBtnText: "Próximo",
    prevBtnText: "Anterior",
    doneBtnText: "Finalizar",
    allowClose: false,
    popoverClass: "tour-popover",
    progressText: `{{current}} de {{total}}`,
    steps: [
      {
        element: `#${tourIds.questions}`,
        popover: {
          title: "Conversas de pré-venda",
          description:
            "Neste campo, você irá selecionar as conversas relativas às mensagens de pré-venda, podendo responder as dúvidas dos compradores sobre seus produtos",
          side: "right",
        },
      },
      {
        element: `#${tourIds.messages}`,
        popover: {
          title: "Conversas de pós-venda",
          description:
            "Clique aqui para visualizar as conversas dos compradores após a venda. Aqui você encontrará mensagens relacionadas a produtos já vendidos",

          side: "right",
        },
      },
      {
        element: `#${tourIds.claims}`,
        popover: {
          title: "Reclamações de compra",
          description:
            "Clique aqui para visualizar as reclamações dos compradores sobre uma compra. Por exemplo, no Mercado Livre, esta seção é equivalente à seção de reclamações que um comprador pode abrir após iniciar uma mensagem de pós-venda",
          side: "right",
        },
      },
      {
        element: `#${tourIds.chats}`,
        popover: {
          title: "Chat (Shopee)",
          description:
            "Aqui, você pode visualizar as conversas de chat entre você e os compradores. Por ora, esta seção é especialmente dedicada à Shopee, que possui um sistema de interação diferente dos outros marketplaces",
          side: "right",
        },
      },
      {
        element: `#${tourIds.chatSidebar}`,
        popover: {
          title: "Seção lateral de conversas",
          description:
            "Esta seção exibe as conversas escolhidas (Pré-venda, Pós-venda, Reclamações ou Chat). Além disso, oferece recursos como barra de pesquisa, filtros e opções de ordenação das conversas",
          side: "right",
        },
      },
      {
        element: `#${tourIds.activeChat}`,
        popover: {
          title: "Conversa ativa",
          description:
            "Aqui, você tem acesso a conversa selecionada com todos os seus detalhes. Além de poder responder o comprador, você pode verfificar os detalhes do produto ou pedido no cabeçalho. As informações podem incluir anúncio, foto do produto, valor do pedido, quantidade em estoque, código SKU, data do pedido, entre outras opções",
          side: "right",
        },
      },
      {
        element: `#${tourIds.notification}`,
        popover: {
          title: "Notificações",
          description:
            "Aqui, você pode visualizar as notificações. Uma notificação nova aparece quando há uma nova mensagem do comprador (independentemente do tipo de conversa). O ícone de notificação recebe um contador, indicando a quantidade de notificações recebidas. Ao selecionar uma notificação, você é redirecionado para a conversa correspondente",
          side: "left",
        },
      },
      {
        element: `#${tourIds.account}`,
        popover: {
          title: "Configurações da conta",
          description:
            "Nesta área, você tem acesso a todos os recursos pertinentes às configurações da conta e de seu perfil. Aqui, você pode:<br/>- Integrar ao Weello suas contas dos marketplaces;<br/>- Personalizar a aparência;<br/>- Adicionar novos usuários;<br/>- Verificar informações pessoais;<br/>- Encerrar a sessão",
          side: "left",
        },
      },
    ],
  });

  return driverObj;
};
