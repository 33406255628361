import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

export const Seo = (props) => {
  const { title } = props;

  const fullTitle = title ? title + " Weello" : "Weello";

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
