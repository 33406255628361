import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { paths } from 'src/paths';

const Page = () => {
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down('desktop'));

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={desktopDown ? "h1" : "h4"}>
            500: Erro interno do servidor
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            Você tentou alguma rota obscura ou veio aqui por engano. Seja qual
            for, tente usar a navegação.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button component={RouterLink} href={paths.dashboard.index}>
              Voltar para a página inicial
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;

