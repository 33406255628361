import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material"
import NearMeIcon from '@mui/icons-material/NearMe';
import { neutral, primary } from "src/theme/colors";
import { useTour } from "src/hooks/use-tour";
import { usePopover } from "src/hooks/use-popover";

export const TutorialPrimeiroAcessoListItem = (props) => {
    const { popover } = props;
    const startTour = () => {
        popover.handleClose();
        useTour().drive();
      };

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{
                pt: 0,
                pb: 0,
                "&:hover": {
                    backgroundColor: primary["alpha8"],
                },
                }}
                onClick={startTour}
            >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <NearMeIcon sx={{color: neutral[500]}} />
                    <ListItemText color="text.secondary" primary="Tutorial de primeiro acesso" />
                </Stack>
            </ListItemButton>
        </ListItem>
    )
}