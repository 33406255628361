import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,  
};
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const genereateToken = async () => {
  if(Notification){
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        return;
      }
}

const token = await getToken(messaging, {
    vapidKey:
      process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  window.localStorage.setItem("fb-token", token);
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "change-url") {
        window.location.href = event.data.newUrl;
      }
    });
  }
  return token;
};
