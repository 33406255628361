import React from "react";
import { Typography, Stack, useMediaQuery } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { neutral } from "src/theme/colors";
import { AnnouncementItemFull } from "./AnnouncementItemFull";

export const AnnouncementItemShippingInfo = ({ freeShipping, logisticType }) => {
    const desktopDown = useMediaQuery((theme) => theme.breakpoints.down("desktop"));
    const getIconColor = () => {
        if (freeShipping) {
            return "success.main";
        }
        return neutral[500];
    };

    const getShippingInfo = () => {
        if (freeShipping) {
            return "Frete grátis";
        }
        return "Pago pelo comprador";
    };

    if (freeShipping === null) {
        return (
            logisticType && (
                <AnnouncementItemFull logisticType={logisticType} className="ml-2" style={{ marginTop: '20px' }} />
            )
        );
    }

    return (
        <Stack
            display="flex"
            direction="row"
            alignItems="center"
            ml={0}
            sx={{
                columnGap: 0.5,
            }}
            width="100%"
        >
            <LocalShippingIcon sx={{ color: getIconColor() }} />
            <Typography variant="subtitle2" sx={{ color: getIconColor(), fontSize: '12px' }}>
                {getShippingInfo()}
            </Typography>
            {logisticType && <AnnouncementItemFull logisticType={logisticType} className="ml-2" style={{ marginTop: '20px' }} />}
        </Stack>
    );
};
