import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";
import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import { usePopover } from "src/hooks/use-popover";
import { tourIds } from "src/utils/tour-ids";
import { NotificationsPopover } from "./notifications-popover";
import { useNotificationCounter } from "src/contexts/notification-counter-context";

export const NotificationsButton = () => {
  const popover = usePopover();
  const { notificationCounter, notificationsTotalCount } = useNotificationCounter();

  return (
    <>
      <Tooltip title="Notificações">
        <IconButton
          id={tourIds.notification}
          ref={popover?.anchorRef}
          onClick={popover?.handleOpen}
          sx={{ marginRight: "8px !important" }}
        >
          <Badge color="error" badgeContent={notificationsTotalCount()}>
            <div className="pulse" style={{ maxHeight: "24px" }}>
              <SvgIcon>
                <Bell01Icon />
              </SvgIcon>
              {notificationsTotalCount() > 0 ? (
                <span className="pulse-ring"></span>
              ) : null}
            </div>
          </Badge>
        </IconButton>
      </Tooltip>

      <NotificationsPopover
        anchorEl={popover?.anchorRef?.current}
        onClose={popover?.handleClose}
        open={popover?.open}
      />
    </>
  );
};
