import React from "react";
import {
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
  Grid,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Sort } from "src/sections/dashboard/chat/sort";
import FilterAltOff from "@mui/icons-material/FilterAltOff";
import { getStatus } from "src/utils/utils-returns";

const FilterBar = ({
  filters,
  setFilters,
  onKeyDownSearch,
  handleResetFilters,
}) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md>
        <TextField
          fullWidth
          label="Pesquisar devolução"
          value={getStatus(filters.query)}
          onChange={(e) => setFilters({ ...filters, query: e.target.value })}
          onKeyDown={onKeyDownSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md="auto">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Sort />
          <IconButton onClick={handleResetFilters}>
            <Tooltip title="Remover filtros">
              <FilterAltOff />
            </Tooltip>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
