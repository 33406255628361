import { Badge, Stack, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import { TopNavItem } from "./top-nav-item";
import React from "react";
import { useNotificationCounter } from "src/contexts/notification-counter-context";
import { useChatKey } from "src/contexts/chat-key-context";

export const TopNavSection = (props) => {
  const { items = [], pathname } = props ? props : {};
  const { notificationCounter } = useNotificationCounter();
  const { setChatKey } = useChatKey(); // Certifique-se de que você está chamando o hook corretamente aqui
  
  const handleItemClick = () => {
    setChatKey(''); // Reseta o chatKey quando qualquer item é clicado
  };
  
  return (
    <React.Fragment>
      <Stack
        component="ul"
        direction="row"
        spacing={1}
        sx={{
          listStyle: "none",
          m: 0,
          p: 0,
        }}
      >
        {items?.map((item, index) => {
          const checkPath = !!(item.path && pathname);
          const partialMatch = checkPath ? pathname.includes(item.path) : false;
          const exactMatch = checkPath ? pathname === item.path : false;

          // Branch

          if (item.items) {
            return (
              <TopNavItem
                active={partialMatch}
                disabled={item.disabled}
                icon={item.icon}
                items={item.items}
                key={item.title + index}
                label={item.label}
                title={item.title}
                onClick={handleItemClick} 
              />
            );
          }

          // Leaf
          const type = item.path
            .split("/")
            .pop()
            .substring(0, item.path.split("/").pop().length - 1);          
            const badgeContent = +notificationCounter[`${type}s`] || 0;
            const tooltipTitle = item.title.toLowerCase().includes('perguntas') ? 'Não respondida(s)' : 'Não lida(s)';
            const badge = (
              <Badge
              key={item.title + index}
              badgeContent={badgeContent}
              color="error">
                <TopNavItem
                  active={exactMatch}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  label={item.label}
                  path={item.path}
                  title={item.title}
                  onClick={handleItemClick}
                />
              </Badge>);
          return (
            badgeContent > 0 ? (
              <Tooltip title={tooltipTitle} arrow placement="bottom">
                {badge}
              </Tooltip>
            ) : (
              badge
            )
          );
        })}
      </Stack>
    </React.Fragment>
  );
};

TopNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};
