const ClaimIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 510.47 488.99"
      {...props}
    >
      <path
        d="M502.59,386.01L305.6,44.82c-10.51-18.21-29.34-29.07-50.36-29.08c0,0,0,0,0,0
		c-21.02,0-39.85,10.87-50.36,29.08L7.89,386.01c-10.51,18.21-10.51,39.95,0,58.16c10.51,18.21,29.34,29.08,50.36,29.08h393.98
		c21.02,0,39.85-10.87,50.36-29.08C513.1,425.96,513.1,404.22,502.59,386.01z M476.47,429.09c-5.06,8.76-14.12,14-24.25,14H58.25
		c-10.12,0-19.19-5.24-24.25-14c-5.06-8.77-5.06-19.23,0-28L230.98,59.9c5.06-8.76,14.13-14,24.25-14c10.12,0,19.19,5.23,24.25,14
		l196.99,341.19C481.54,409.85,481.53,420.32,476.47,429.09z"
      />
      <path
        d="M255.34,139.24c-21.8,0-38.71,19.03-36.16,40.68l15.79,133.79c1.21,10.27,9.92,18.02,20.27,18.02
		c10.33,0,19.04-7.72,20.26-17.98l16-133.77C294.09,158.31,277.17,139.24,255.34,139.24z"
      />
      <path
        d="M255.35,347.04c-8.14,0-15.02,2.81-20.63,8.41c-5.61,5.61-8.42,12.49-8.42,20.63c0,8.14,2.81,15.02,8.42,20.63
		c5.61,5.61,12.49,8.42,20.63,8.42c8.14,0,15.02-2.81,20.63-8.42c5.61-5.61,8.41-12.49,8.41-20.63c0-8.14-2.8-15.02-8.41-20.63
		C270.36,349.85,263.48,347.04,255.35,347.04z"
      />
    </svg>
  );
};

export default ClaimIcon;
