import { createContext, useContext, useState, useMemo } from "react";

const ChangePasswordContext = createContext();

export const ChangePasswordProvider = ({ children }) => {
  const [showChangePassModal, setShowChangePassModal] = useState(false);

  const contextValue = useMemo(() => {
    return { showChangePassModal, setShowChangePassModal };
  }, [showChangePassModal]);

  return (
    <ChangePasswordContext.Provider value={contextValue}>
      {children}
    </ChangePasswordContext.Provider>
  );
};

export const useChangePassword = () => {
  const context = useContext(ChangePasswordContext);

  if (!context) throw new Error("Change Password context not found");
  return context;
};
