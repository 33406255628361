import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import LockIcon from "src/icons/untitled-ui/duocolor/lock-01-1";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
  useMediaQuery
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { useSettings } from "src/hooks/use-settings";
import { useUser } from "src/contexts/user-context";
import { useChangePassword } from "src/contexts/change-password-context";
import { useThemeSwitcher } from "src/hooks/use-theme-switcher";
import { FreshworksWidgetProvider } from "src/contexts/fresh-works-widget-context";
import { DuvidasMaisFrequentesItemList } from "../horizontal-layout/HelperDropdown/components/duvidas-mais-frequentes";
import { FaleConoscoListItem } from "../horizontal-layout/HelperDropdown/components/fale-conosco";
import { usePathname } from "src/hooks/use-pathname";
import { CentralDuvidasListItem } from "../horizontal-layout/HelperDropdown/components/cemtral-duvidas";

export const AccountPopover = (props) => {
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down('desktop'));
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  const { user } = useUser();
  const { handleDrawerOpen } = useSettings();
  const { setShowChangePassModal } = useChangePassword();
  const { getIcon, getTooltip, getAction, getMenuItem, handleThemeChange } = useThemeSwitcher();
  const [ openHelpCenter, setOpenHelperCenter ] = useState(false);
  
  const checkConversationScreen = () => {
    const pathname = usePathname().replace("/v1/", "");
    const conversations = ["questions", "messages", "claims", "chats"];
    return conversations.includes(pathname);
  };
  const isConversationScreen = checkConversationScreen();
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));

  const handleClickHelpCenter = () => {
    setOpenHelperCenter(!openHelpCenter);
  };

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      await auth.signOut();

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, onClose]);

  const handleThemeToggle = () => {
    const action = getAction();
    handleThemeChange(action);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 250 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" noWrap>
          {user.name}
        </Typography>
        <Typography color="text.secondary" variant="body2" noWrap>
          {user.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.dashboard.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Configurações</Typography>}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setShowChangePassModal(true);
            onClose();
          }}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <LockIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Alterar senha</Typography>}
          />
        </ListItemButton>
        { 
          desktopDown && 
          <>
            <ListItemButton
              onClick={handleThemeToggle}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                { getIcon() }
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{getMenuItem()}</Typography>}
              />
            </ListItemButton>

            <Divider sx={{ my: "0 !important" }} />
              <ListItemButton 
                onClick={handleClickHelpCenter}
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5,
                }}
                >
                <ListItemIcon>
                  <HelpOutlineIcon sx={{ fontSize: "24px" }} />
                </ListItemIcon>
                <ListItemText primary="Central de Ajuda" />
                {openHelpCenter ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openHelpCenter} timeout="auto" unmountOnExit>
                <List>
                  <CentralDuvidasListItem />
                  <FaleConoscoListItem />
                </List>
              </Collapse>
          </>
        } 
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Sair
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
