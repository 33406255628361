import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { getChatType } from "src/utils/get-chat-type";
import ChatApi from "../api/chat";
export const getQuestionAnnoucement = createAsyncThunk(
  "chat/getQuestionAnnoucement",
  async (questionId, { dispatch, getState }) => {
    const res = await ChatApi.getQuestionAnnoucement(questionId);
    dispatch(
      getMessagesUserAndAnnoucement({
        annoucement_id: getState().chat?.activeChat?.annoucement_id,
        marketplace_from_id: getState().chat?.activeChat?.marketplace_from_id,
      })
    );
    dispatch(setReadMessageOne(questionId));
    return res;
  }
);

export const getMessagesUserAndAnnoucement = createAsyncThunk(
  "chat/getMessagesUserAndAnnoucement",
  async (params, { dispatch, getState }) => {
    const res = await ChatApi.getMessagesUserAndAnnoucement(params);
    // dispatch(getQuestions({ ...getState().chat?.filters }));
    return res;
  }
);

export const getQuestions = createAsyncThunk(
  "chat/getQuestions",
  async (params, { dispatch }) => {
    if (!params?.type) {
      params = { ...params, type: getChatType() };
    }
    const question = await ChatApi.getQuestions(params);

    dispatch(getNotifications());
    var params = new URLSearchParams(window.location.search);
    var key = params.get("key");
    if (key) {
      dispatch(getQuestionAnnoucement(key));
    }
    dispatch(getTotalUnanswered());
    return question;
  }
);

export const getNotifications = createAsyncThunk(
  "chat/getNotifications",
  async (params, thunkAPI) => {
    const question = await ChatApi.getQuestions({
      order_date: "DESC",
      status: "pending",
      page: 0,
      size: 100,
      type: "question",
    });
    return question;
  }
);
export const getTotalUnanswered = createAsyncThunk(
  "chat/getTotalUnanswered",
  async (params, thunkAPI) => {
    const totalUnanswered = await ChatApi.getTotalUnanswered();
    return totalUnanswered;
  }
);

export const setSendMessage = createAsyncThunk(
  "chat/setSendMessage",
  async (message, thunkAPI) => {
    const res = await ChatApi.setSendMessage(message);
    //thunkAPI.dispatch(getQuestionAnnoucement(message?.id));
    thunkAPI.dispatch(getQuestions());
    return res;
  }
);

export const setReadMessage = createAsyncThunk(
  "chat/setReadMessage",
  async (message, thunkAPI) => {
    const res = await ChatApi.setReadMessage(message);
    return res;
  }
);

export const setReadMessageOne = createAsyncThunk(
  "chat/setReadMessageOne",
  async (message, thunkAPI) => {
    const res = await ChatApi.setReadMessage(message);
    thunkAPI.dispatch(getNotifications());
    return res;
  }
);

export const getQuestion = createAsyncThunk(
  "chat/getQuestion",
  async (messageId, thunkAPI) => {
    const res = await ChatApi.getQuestion(messageId);
    return res;
  }
);

export const getQuestionId = createAsyncThunk(
  "chat/getQuestionId",
  async (messageId, thunkAPI) => {
    const res = await ChatApi.getQuestionId(messageId);
    thunkAPI.dispatch(getNotifications());
    return res;
  }
);

export const setBlockUser = createAsyncThunk(
  "chat/setBlockUser",
  async (messageId, thunkAPI) => {
    const res = await ChatApi.setBlockUser(messageId);
    return res;
  }
);

export const setHiddenMessage = createAsyncThunk(
  "chat/setHiddenMessage",
  async (messageId, { dispatch, getState }) => {
    const res = await ChatApi.setHiddenMessage(messageId);
    dispatch(getQuestionAnnoucement(messageId));
    return res;
  }
);

export const setDeleteMessage = createAsyncThunk(
  "chat/setDeleteMessage",
  async (messageId, { dispatch }) => {
    const res = await ChatApi.setDeleteMessage(messageId);
    dispatch(getQuestionAnnoucement(messageId));
    return res;
  }
);

export const getMessagesByQuestion = createAsyncThunk(
  "chat/getMessagesByQuestion",
  async (questionId, thunkAPI) => {
    const res = await ChatApi.getMessagesByQuestion(questionId);
    return res;
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    loading: {
      questions: false,
      messages: false,
      notifications: false,
      annoucement: false,
      order: false,
      items: false,
    },
    chats: [],
    totalUnanswered: { questions: 0, claims: 0, message: 0, chat: 0 },
    length: 0,
    activeChat: null,
    activeAnnouncement: null,
    activeMessages: [],
    notifications: [],
    filters: {
      query: "",
    },
  },
  reducers: {
    setQuestions: (state, action) => {
      state.chats = action.payload;
    },

    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setMarketplaces: (state, action) => {
      state.filters.marketplaces = action.payload;
    },
    setChatTypes: (state, action) => {
      state.filters.chatTypes = action.payload;
    },
    clearFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setActiveMessages: (state, action) => {
      state.activeMessages = action.payload;
    },
    setActiveAnnouncement: (state, action) => {
      state.activeAnnouncement = action.payload;
    },
    setLoadingMessages: (state) => {
      state.loading.messages = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessagesByQuestion.fulfilled, (state, { payload }) => {
        state.activeMessages = payload;
      })
      .addCase(getQuestionId.fulfilled, (state, { payload }) => {
        state.activeChat = payload.question;
        state.activeAnnoucement = payload.annoucement;
        state.activeMessages = payload.messages;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload;
      })
      .addCase(getTotalUnanswered.fulfilled, (state, { payload }) => {
        state.totalUnanswered = payload;
      })
      .addCase(getQuestionAnnoucement.fulfilled, (state, { payload }) => {
        state.activeAnnouncement = payload;
      })

      .addCase(
        getMessagesUserAndAnnoucement.fulfilled,
        (state, { payload }) => {
          state.loading.messages = false;
          state.activeMessages = payload.rows;
        }
      )
      .addCase(getMessagesUserAndAnnoucement.rejected, (state) => {
        state.activeMessages = [];
        state.loading.messages = false;
      })
      .addCase(getQuestions.pending, (state) => {
        state.loading.questions = true;
      })
      .addCase(getQuestions.rejected, (state) => {
        state.loading.questions = false;
      })
      .addCase(getQuestions.fulfilled, (state, { payload }) => {
        state.chats = payload;
        state.length = payload.count;
        state.loading.questions = false;
      })

      .addCase(setReadMessage.fulfilled, (state, { payload }) => {
        state.activeChat = payload;
        state.chats = state.chats.map((chat) => {
          if (chat.id === payload.id) {
            return { ...chat, is_unread: false, unread_count: 0 };
          }
          return chat;
        });
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        if (
          state.chats?.filter((chat) => chat.id === action.payload.id)
            .length === 1
        ) {
          state.chats = sortBy(
            state.chats.map((chat) => {
              if (chat.id === action.payload.id) {
                if (activeChat.id !== action.payload.id) {
                  return action.payload;
                }
              }
              return chat;
            }),
            ["isUnread", "createdAt"]
          ).reverse();
        } else {
          state.chats.push(action.payload);
          state.length = state.length + 1;
        }
      })
      .addCase(setBlockUser.fulfilled, (state, action) => {
        action.dispatch(
          getMessagesUserAndAnnoucement({
            annoucement_id: state.activeAnnouncement?.annoucementId,
            marketplace_from_id: state.activeChat?.marketplaceFromId,
          })
        );
      })
      .addCase(setHiddenMessage.fulfilled, (state, action) => {
        action.dispatch(
          getMessagesUserAndAnnoucement({
            annoucement_id: state.activeAnnouncement?.annoucementId,
            marketplace_from_id: state.activeChat?.marketplaceFromId,
          })
        );
      });
  },
});

export const {
  setQuestions,
  setActiveChat,
  setMarketplaces,
  setChatTypes,
  clearFilter,
  setFilters,
  setActiveMessages,
  setActiveAnnouncement,
  setLoadingMessages,
} = chatSlice.actions;
export default chatSlice.reducer;
