import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { httpConfig } from 'src/config';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(httpConfig.apiUrl[process.env.REACT_APP_ENV], {
      withCredentials: true,
      autoConnect: true,
      transports: ['websocket']
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
