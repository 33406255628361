import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import QckAnswersApi from "src/api/qckAnswers";

export const getQuickAnswers = createAsyncThunk(
  "quick/getQuickAnswers",
  async (_, thunkAPI) => {
    const question = await QckAnswersApi.getQuickAnswers();
    return question;
  }
);

export const setQuickAnswers = createAsyncThunk(
  "quick/setQuickAnswers",
  async (message, thunkAPI) => {
    const res = await QckAnswersApi.setQuickAnswers(message);
    thunkAPI.dispatch(getQuickAnswers());
    return res;
  }
);

export const delQuickAnswers = createAsyncThunk(
  "quick/delQuickAnswers",
  async (id, thunkAPI) => {
    const res = await QckAnswersApi.delQuickAnswers(id);
    thunkAPI.dispatch(getQuickAnswers());

    return res;
  }
);

export const qckAnswersSlice = createSlice({
  name: "qckAnswers",
  initialState: {
    loading: false,
    answers: [],
    length: 0,
    edited: null,
  },
  reducers: {
    // setQuestions: (state, action) => {
    //   state.chats = action.payload;
    // },
    // setActiveChat: (state, action) => {
    //   state.activeChat = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuickAnswers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuickAnswers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.answers = payload;
        state.length = payload.length;
      })
      .addCase(getQuickAnswers.rejected, (state) => {
        state.loading = false;
        state.answers = [];
      });
  },
});

// export const { setQuestions, setActiveChat } = chatSlice.actions;
export default qckAnswersSlice.reducer;
