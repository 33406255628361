import styled from "styled-components";
import { primary } from "src/theme/colors";
import { Typography } from "@mui/material";

export const ItemsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 16px 8px 16px;
  font-size: 0.875rem;
  :hover {
    color: ${primary.main};
  }
  li + li {
    margin: 4px 0;
  }
`;

export const AnchorLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const ItemTypography = styled(Typography)`
  font-weight: 400;
  font-size: 0.875rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const Title = styled(Typography)`
  font-size: 1.1rem;
  font-weight: 600;
  padding: 8px 16px 8px 16px;
`;
