import { createContext, useContext, useState, useMemo } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "",
    tenant_id: "",
    avatar: '',
    send_parameter: window.localStorage.getItem("send_parameter") || null,
  });

  const contextValue = useMemo(() => {
    return { user, setUser };
  }, [user]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) throw new Error("User context not found");
  return context;
};
