import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material"
import ThreePIcon from '@mui/icons-material/ThreeP';
import { neutral, primary } from "src/theme/colors";
import { useEffect, useLayoutEffect } from "react";
import { useUser } from "src/contexts/user-context";

export const FaleConoscoListItem = () => {
  const { user } = useUser();

  const handleFreshWorksWidget = () => {
    if (window.FreshworksWidget) {
      if (user) {
        window.FreshworksWidget('identify', 'ticketForm', {
          email: user.email,
          name: user.name,
        });
        window.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
      }
      window.FreshworksWidget('open');
    }
  }

  return (
    <>
      <ListItem disablePadding onClick={handleFreshWorksWidget}>
        <ListItemButton
          sx={{
            pt: 0,
            pb: 0,
            "&:hover": {
              backgroundColor: primary["alpha8"],
            },
          }}
        >
          <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
            <ThreePIcon sx={{color: neutral[500]}}/>
            <ListItemText primary="Fale conosco" />
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  )
}