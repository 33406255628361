const ChatIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 510.47 488.99"
      {...props}
    >
      <path
        d="M436.07,0H74.41C33.31,0,0,33.31,0,74.41v251.87c0,36.37,26.24,66.73,60.78,73.14
		c1.36,0.25,2.77,0.36,4.15,0.38l34.59,0.37l0.3,88.82l136.77-88.31h199.49c41.09,0,74.4-33.31,74.4-74.4V74.41
		C510.47,33.31,477.16,0,436.07,0z M476.99,326.28c0,22.56-18.35,40.91-40.91,40.91H226.71l-97.93,68.58l-0.14-68.58H74.41
		c-22.57,0-40.92-18.35-40.92-40.91V74.41c0-22.57,18.35-40.92,40.92-40.92h361.67c22.56,0,40.91,18.35,40.91,40.92V326.28z"
      />
      <path
        d="M279.41,205.44c19.05-9.08,32.3-28.35,32.3-50.85c0-31.19-25.28-56.47-56.47-56.47
		c-31.19,0-56.47,25.28-56.47,56.47c0,22.5,13.26,41.77,32.3,50.85c-43.27,10.81-75.37,49.84-75.37,96.46h199.07
		C354.77,255.28,322.68,216.26,279.41,205.44z"
      />
    </svg>
  );
};

export default ChatIcon;
