import { createContext, useContext, useState, useMemo } from "react";

export const SignatureContext = createContext();

export const SignatureProvider = ({ children }) => {
  const [signaturesAll, setSignaturesAll] = useState({
    id: "",
    accountId: "",
    userId: "",
    signature: "",
    greeting: "",
  });


  const contextValue = useMemo(
    () => ({
      signaturesAll,
      setSignaturesAll,
    }),
    [signaturesAll]
  );

  return (
    <SignatureContext.Provider value={contextValue}>
      {children}
    </SignatureContext.Provider>
  );
};
