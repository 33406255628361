import { combineReducers } from "@reduxjs/toolkit";
import chatReducer from "src/slices/chat";
import qckReducer from "src/slices/qckAnswers";
import { reducer as usersReducer } from "src/slices/user";
import { reducer as userGroupReducer } from "src/slices/usersGroups";
import { reducer as userParameters } from "src/slices/userParameters";
import { reducer as accountsReducer } from "src/slices/accounts";
import { reducer as returnsReducer } from "src/slices/returns";

export const rootReducer = combineReducers({
  chat: chatReducer,
  qckAnswers: qckReducer,
  users: usersReducer,
  users_groups: userGroupReducer,
  user_parameters: userParameters,
  accounts: accountsReducer,
  returns: returnsReducer,
});
