import { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GuestGuard } from "src/guards/guest-guard";

import { Layout as AuthLayout } from "src/layouts/auth/classic-layout";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

import Error401Page from "src/pages/401";
import Error500Page from "src/pages/500";
import { modules } from "../modules";
import Home from "src/pages/dashboard/account/metrics/home";
import ReturnTable from "src/pages/return/return";

const LoginPage = lazy(() => import("src/pages/auth/login"));
const RegisterPage = lazy(() => import("src/pages/auth/register"));
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const AccountPage = lazy(() => import("src/pages/dashboard/account/account"));
const AccountUsersPage = lazy(() =>
  import("src/pages/dashboard/account/users")
);
const ConfirmationPage = lazy(() =>
  import("src/pages/auth/AccountConfirmation/AccountConfirmation")
);
const PasswordRecovery = lazy(() => import("src/pages/auth/password-recovery"));
const PasswordReset = lazy(() =>
  import("src/pages/auth/PasswordReset/PasswordReset")
);

const NotFoundPage = lazy(() => import("src/pages/NotFoundPage/NotFoundPage"));

export const routes = [
  {
    path: "auth",
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          </GuestGuard>
        ),
        children: [
          {
            path: "",
            element: <Navigate to={modules.start.path} />,
          },
          {
            index: true,
            path: modules.login.path,
            element: <LoginPage />,
          },
          {
            path: "/auth/register",
            element: <RegisterPage />,
          },
          {
            path: "/auth/confirmation",
            element: <ConfirmationPage />,
          },
          {
            path: "/auth/forgot_password",
            element: <PasswordRecovery />,
          },
          {
            path: "/auth/reset-password",
            element: <PasswordReset />,
          },
        ],
      },
    ],
  },
  {
    path: "/v1",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/v1/questions" />,
      },
      {
        path:"/v1/home",
        element: <Home/>
      },
      {
        path: "/v1/questions",
        element: <ChatPage />,
      },
      {
        path: "/v1/messages",
        element: <ChatPage />,
      },
      {
        path: "/v1/chats",
        element: <ChatPage />,
      },
      {
        path: "/v1/claims",
        element: <ChatPage />,
      },
      {
        path: "/v1/returns",
        element: <ReturnTable />,
      },
      {
        path: modules.account.path,
        element: <AccountPage />,
      },
      {
        path: modules.accountUsersNew.path,
        element: <AccountUsersPage />,
      },
      {
        path: modules.accountUsersEdit.path,
        element: <AccountUsersPage />,
      },
    ],
  },
  {
    path: "401",
    element: <Error401Page />,
  },

  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "404",
    element: <NotFoundPage />,
  },
  {
    path: "*",
    element: <Navigate to="/auth/login" />,
  },
];
