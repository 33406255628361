import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Avatar, Badge, IconButton, Skeleton, Grid, Button, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { getLabels } from "src/utils/utils-returns";
import DropzoneComponent from "src/components/dropzone-component";
import { api } from "src/services/api";

const StageThree = ({
  loading,
  s3Files,
  setS3Files,
  handleRemoveS3FileFromList,
  handleBack,
  handleSendReviewFail,
  selectedReason,
  user,
  selectedReturnId,
  setLoading,
  updateAttachmentStatus
}) => {
  const [description, setDescription] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const labels = getLabels(selectedReason);
  
  useEffect(() => {
    const fetchDescription = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/v1/claims/get-description/${selectedReturnId}`
        );
        
        setInitialDescription(response.data.description);
        setDescription(response.data.description);
      } catch (error) {
        toast.error("Erro ao carregar descrição", {
          position: "bottom-right",
        });
      } finally {
        setLoading(false);
      }
    };

    if (selectedReturnId) {
      fetchDescription();
    }
  }, [selectedReturnId, setDescription, setLoading]);

  return (
    <Box>
      <Typography
        id="modal-modal-title"
        variant="h4"
        component="h2"
        textAlign="center"
        sx={{ mt: 1 }}
      >
        {labels.title}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
        {labels.description}
      </Typography>
      <Box sx={{ position: "relative" }}>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Descreva o problema"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{ maxLength: 320 }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 8,
            right: 16,
            color: "text.secondary",
            fontSize: 13,
          }}
        >
          {`${description?.length ?? 0}/320`}
        </Box>
      </Box>
      <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
        Para que possamos te ajudar, inclua fotos do produto, do pacote
        original e da embalagem dos Correios/transportadora.
      </Typography>
      {loading ? (
        <Box mt={2} display="flex" flexWrap="wrap">
          {[...Array(10).keys()].map((item) => (
            <Skeleton key={item} variant="rectangular" width={45} height={45} sx={{ margin: 1 }} />
          ))}
        </Box>
      ) : (
        s3Files.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2">Arquivos já armazenados:</Typography>
            <Box display="flex" flexWrap="wrap">
              {s3Files.map((file, index) => (
                <Badge
                  key={index}
                  overlap="circular"
                  badgeContent={
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveS3FileFromList(file.key)}
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.7)",
                        "&:hover": {
                          bgcolor: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Avatar variant="rounded" src={file.url} alt={file.name} sx={{ margin: 1, width: 45, height: 45 }} />
                </Badge>
              ))}
            </Box>
          </Box>
        )
      )}
      <DropzoneComponent
        s3Files={s3Files}
        setS3Files={setS3Files}
        user={user}
        selectedReturnId={selectedReturnId}
        setLoading={setLoading}
        allowVideos={false}
        updateAttachmentStatus={updateAttachmentStatus}
      />
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mt: 4 }}>
        <Button color="primary" sx={{ mr: 2 }} onClick={handleBack}>
          Voltar
        </Button>

        <LoadingButton variant="contained" color="primary" onClick={handleSendReviewFail} loading={loading}>
          Enviar
        </LoadingButton>
      </Grid>
    </Box>
  );
};

export default StageThree;
