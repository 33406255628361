import { api } from "src/services/api";

export const useNotification = async (message_id) => {
  const token = window.localStorage.getItem("fb-token");
  if (Notification && Notification.permission === "granted" && token) {
    await api.post("/v1/notification/send", {
      message_id,
      token,
    });
  }
};
