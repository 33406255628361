import axios from "axios";
import { getToken } from "./auth";
import { httpConfig } from "src/config";

const api = axios.create({
  baseURL: httpConfig.apiUrl[process.env.REACT_APP_ENV],
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
