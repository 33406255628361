import { createContext, useContext, useState, useMemo } from "react";

const MobileMessageContext = createContext();

export const MobileMessageProvider = ({ children }) => {
  const [mobileMessage, setMobileMessage] = useState(false);

  const contextValue = useMemo(() => {
    return { mobileMessage, setMobileMessage };
  }, [mobileMessage]);

  return (
    <MobileMessageContext.Provider value={contextValue}>
      {children}
    </MobileMessageContext.Provider>
  );
};

export const useMobileMessage = () => {
  const context = useContext(MobileMessageContext);

  if (!context) throw new Error("Mobile message context not found");
  return context;
};
