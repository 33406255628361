import localforage from "localforage";

const dbName = 'weello-database';
const storeName = 'user_preferences_store';
const version = 2.0; 

localforage.config({
  driver: [localforage.INDEXEDDB],
  name: dbName,
  storeName: storeName,
  version: version
});

export const setItem = (key, value) => {
  try {
      localforage.setItem(key, value, function() {
        localforage._dbInfo.db.close();
      })
      .catch(function(err) {
        console.error('Set localforage: ', err);
      });
  } catch(e) {
    console.error(e);
  }
}

export const getItem = (key) => {
    localforage.getItem(key)
    .then(function(readValue) {
      localforage._dbInfo.db.close();
      return readValue;
    })
    .catch(function(err) {
      console.error('Read localforage: ', err);
    });
}
