import { httpConfig } from "src/config";
import { baseCoreUrl } from "./http";

const url = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const STORAGE_KEY = "accessToken";

const UserParametersApi = {
  findAllParameters: async ({ page = 0, size = 10, query = "" }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${url}/v1/parameters/users/groups?${new URLSearchParams({
          page,
          size, 
          query,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findParameter: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/parameters/users/groups/${parseInt(id)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findUserParameters: async ({ page = 0, size = 10, id }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${url}/v1/parameters/users/values?${new URLSearchParams({
          page,
          size,
          userId: id,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addUserParameter: async (data) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/parameters/users/values`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delUserParameter: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/parameters/users/values/${parseInt(id)}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default UserParametersApi;
