import {
    CardContent,
    Typography,
    Card,
    CardActionArea,
    Grid,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import React from "react";
  
  const CounterCardMobile = ({
    title,
    value,
    borderColor,
    onClick,
    isSelected,
    icon,
  }) => {
    return (
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: 3,
          opacity: isSelected ? 1 : 0.7,
          borderLeft: `4px solid ${borderColor}`,
          backgroundColor: isSelected ? `${borderColor}20` : "white",
          height: "80px",  // Reduced height for mobile
          width: "100%",
          display: "flex",
          alignItems: "center",
          transition: "background-color 0.3s ease, transform 0.3s ease",
          transform: isSelected ? "translateY(-10px)" : "translateY(0)",
          "&:hover": {
            backgroundColor: isSelected ? `${borderColor}20` : `${borderColor}40`,
            transform: "translateY(-5px)",
            opacity: 1,
          },
        }}
      >
        <CardActionArea onClick={onClick} sx={{ height: "100%", width: "100%" }}>
          <CardContent
            sx={{ width: "100%", height: "100%", padding: "12px 16px" }}
          >
            <Grid
              container
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",  // Smaller font size for mobile
                    whiteSpace: "break-spaces",
                    wordWrap: "break-word",
                    mb: 1,
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5" color={borderColor}>{value}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };
  
  export default CounterCardMobile;
  