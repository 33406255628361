import React from "react";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const StageOne = ({ loading, handleReviewOk, setStage }) => (
  <>
    <Typography
      id="modal-modal-title"
      variant="h4"
      component="h2"
      textAlign="center"
      sx={{ mt: 1 }}
    >
      O produto chegou como esperado?
    </Typography>
    <Typography
      id="modal-modal-description"
      variant="h6"
      component="h2"
      textAlign="center"
      sx={{ mt: 4 }}
    >
      Se a resposta for sim, a devolução será finalizada
    </Typography>
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 4 }}
    >
      <LoadingButton
        disabled={loading}
        loading={loading}
        type="submit"
        onClick={handleReviewOk}
        variant="contained"
        sx={{ mr: 1 }}
      >
        Sim, chegou como esperado
      </LoadingButton>

      <LoadingButton
        disabled={loading}
        loading={loading}
        type="submit"
        onClick={() => setStage(2)}
      >
        Não, reportar um problema
      </LoadingButton>
    </Grid>
  </>
);

export default StageOne;
