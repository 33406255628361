import { Tooltip, IconButton } from "@mui/material";
import { useThemeSwitcher } from "src/hooks/use-theme-switcher";

export const ThemeButton = () => {
  const { getIcon, getTooltip, getAction, handleThemeChange } = useThemeSwitcher();
    
  return (
    <Tooltip title={getTooltip()}>
      <IconButton onClick={() => handleThemeChange(getAction())}>
      { getIcon() }
      </IconButton>
    </Tooltip>
  );
};