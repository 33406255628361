import { AnchorLink, ItemTypography, ItemsList, Title } from "../helperDropdown.styles"
import { Stack } from "@mui/material";
import { neutral } from "src/theme/colors";
import ArticleIcon from '@mui/icons-material/Article';

const HELPER_BASE_URL = "https://ajuda.weesu.com.br";

export const DuvidasMaisFrequentesItemList = () => {
    return (
        <>
            <Title>Dúvidas mais frequentes</Title>
            <ItemsList>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000179829-alteracão-de-senha`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}}/>
                    <ItemTypography color="text.secondary">
                        Como alterar minha senha
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000179907-adicionar-usuários`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}}/>
                    <ItemTypography color="text.secondary">
                    Como adicionar novos usuários
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000179897-alteracão-de-foto-e-nome-na-conta`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}} />
                    <ItemTypography color="text.secondary">
                    Como alterar minha foto e nome
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000179903-adicionar-integracões`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}} />
                    <ItemTypography color="text.secondary">
                        Como integrar uma conta de marketplace
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            </ItemsList>
        </>
    )
}