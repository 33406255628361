import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FileDropzone = ({ getRootProps, getInputProps, messageBox, acceptedFormats }) => (
  <Box
    {...getRootProps()}
    sx={{
      border: "2px dashed #46c1c5",
      padding: "16px",
      borderRadius: "8px",
      textAlign: "center",
      cursor: "pointer",
      mt: 2,
    }}
  >
    <input {...getInputProps()} />
    <Stack direction="row" justifyContent="center">
      <CloudUploadIcon sx={{ fontSize: "20px", color: "#46c1c5" }} />
      <Typography variant="body1" color="primary" component="span">
        Selecione ou arraste os arquivos aqui
      </Typography>
    </Stack>
    <Typography variant="body2" color="textSecondary">
      {messageBox} {acceptedFormats.join(", ")}.
    </Typography>
  </Box>
);

export default FileDropzone;
