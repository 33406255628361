import { createContext, useContext, useState, useMemo } from "react";

const OpenContext = createContext();

export const OpenProvider = ({ children }) => {
  const [open, setOpen] = useState(true);

  const contextValue = useMemo(() => {
    return { open, setOpen };
  }, [open]);

  return (
    <OpenContext.Provider value={contextValue}>{children}</OpenContext.Provider>
  );
};

export const useOpen = () => {
  const context = useContext(OpenContext);

  if (!context) throw new Error("Open context not found");
  return context;
};
