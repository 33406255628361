const MessageIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 510.47 488.99"
      {...props}
    >
      <circle cx="249.75" cy="286.94" r="20.63" />
      <circle cx="438.69" cy="286.94" r="20.63" />
      <circle cx="344.22" cy="286.94" r="20.63" />
      <path
        d="M462.01,156.44h-52.46V74.28c0-32.92-26.69-59.61-59.61-59.61H60.18c-32.92,0-59.61,26.69-59.61,59.61v201.79
		c0,29.14,21.02,53.47,48.7,58.6c1.09,0.2,2.22,0.29,3.33,0.3l27.71,0.3l0.24,71.16l97.43-62.91v25.45
		c0,26.77,21.7,48.46,48.46,48.46h129.94l89.09,57.52l0.19-57.86l22.53-0.24c0.9-0.01,1.82-0.08,2.7-0.24
		c22.5-4.18,39.59-23.95,39.59-47.64V204.91C510.47,178.14,488.78,156.44,462.01,156.44z M177.97,204.91v106.91l-74.22,51.98
		l-0.11-54.94H60.18c-18.08,0-32.78-14.7-32.78-32.78V74.28c0-18.08,14.7-32.78,32.78-32.78h289.76c18.07,0,32.78,14.7,32.78,32.78
		v82.16H226.43C199.66,156.44,177.97,178.14,177.97,204.91z M488.66,368.97c0,14.69-11.95,26.65-26.65,26.65h-35.33l-0.09,44.67
		l-63.79-44.67H226.43c-14.69,0-26.65-11.95-26.65-26.65V204.91c0-14.7,11.95-26.65,26.65-26.65h235.58
		c14.7,0,26.65,11.95,26.65,26.65V368.97z"
      />
    </svg>
  );
};

export default MessageIcon;
