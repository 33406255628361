import {
  IconButton,
  Tooltip,
  Popover,
  Divider,
  List,
  useMediaQuery} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { usePopover } from "src/hooks/use-popover";
import { usePathname } from "src/hooks/use-pathname";
import { DuvidasMaisFrequentesItemList } from "./components/duvidas-mais-frequentes";
import { FaleConoscoListItem } from "./components/fale-conosco";
import { CentralDuvidasListItem } from "./components/cemtral-duvidas";
import { TutorialPrimeiroAcessoListItem } from "./components/tour-primeiro-acesso";

export const HelperDropdown = () => {
  const popover = usePopover();
  const HELPER_BASE_URL = "https://ajuda.weesu.com.br";

  const checkConversationScreen = () => {
    const pathname = usePathname().replace("/v1/", "");
    const conversations = ["questions", "messages", "claims", "chats"];
    return conversations.includes(pathname);
  };
  const isConversationScreen = checkConversationScreen();
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));

  return (
    <div style={{ position: "relative" }}>
      <Tooltip title="Central de ajuda">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <HelpOutlineIcon sx={{ fontSize: "24px" }} />
        </IconButton>
      </Tooltip>
      <Popover
        elevation={24}
        anchorEl={popover.anchorRef?.current}
        open={popover.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: desktopUp ? "right" : "center",
        }}
        onClose={popover.handleClose}
      >
        <DuvidasMaisFrequentesItemList />
        {desktopUp && isConversationScreen && (
            <>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <TutorialPrimeiroAcessoListItem popover={popover} />
            </>
        )}
        <Divider sx={{ mt: 1, mb: 1 }}/>
        <List>
          <CentralDuvidasListItem />
          <Divider sx={{ mt: 1, mb: 1}}/>
          <FaleConoscoListItem />
        </List>
      </Popover>
    </div>
  );
};
