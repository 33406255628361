import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import TaxiAlertOutlinedIcon from '@mui/icons-material/TaxiAlertOutlined';
import RuleIcon from '@mui/icons-material/Rule';

export const getStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pendente";
      case "handling":
        return "Preparação";
      case "ready_to_ship":
        return "Pronto para despachar";
      case "shipped":
        return "Devolução enviada";
      case "closed":
        return "Encerrado";
      case "not_delivered":
        return "Não entregue";
      case "delivered":
        return "Entregue";
      case "cancelled":
        return "Cancelado";
      case "failed":
        return "Devolução falha";
      case "expired":
        return "Devolução expirada";
      case "not_recived":
        return "Não recebido";
      case "parcial_recived":
        return "Recebimento Parcial";
      case "received_damaged":
        return "Recebimento com avaria";
      case "completed":
        return "Concluída";
      case "received_divergence":
        return "Recebimento com divergência"
      default:
        return status;
    }
};

export const getLabels = (reason) => {
  switch (reason.detail) {
    case "O produto chegou avariado":
      return {
        title: "Qual avaria você vê no produto?",
        description: "Informe se há marcas de uso, manchas, riscos ou outras avarias."
      };
    case "A devolução está incompleta":
      return {
        title: "O que falta na devolução?",
        description: "Informe se chegaram menos produtos ou se faltam peças, acessórios ou outros itens."
      };
    case "O produto devolvido é diferente do que eu enviei":
      return {
        title: "O que foi devolvido?",
        description: "Informe a diferença entre o produto recebido e o enviado."
      };
    case "O produto não está no pacote":
      return {
        title: "O que veio no pacote?",
        description: "Informe se o pacote chegou vazio ou se contém outro produto."
      };
    case "Reportar outro defeito no produto":
      return {
        title: "Qual é o problema com o produto?",
        description: "Informe por que o produto não chegou como esperado."
      };
    case "Ainda não chegou":
      return {
        title: "Pode nos contar um pouco mais?",
        description: "Caso você ainda não tenha recebido o pacote, adicione as informações que possam ser úteis para podermos te ajudar."
      };
    default:
      return {
        title: "Descreva o problema",
        description: "Informe os detalhes do problema."
      };
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "dd MMM HH':'mm 'hs'", { locale: ptBR });
};

export const formatCurrency = (value) => {
  return parseFloat(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const cardsDataExemple = [
  {
    title: "Todas",
    borderColor: "#758694",
    filterKey: "all",
    icon: <FormatListNumberedOutlinedIcon sx={{ 
      width: 35,
      height: 35,
      color: "#758694"
    }}
    />
  },
  {
    title: "Pronto para despachar",
    borderColor: "#758694",
    filterKey: "ready_to_ship",
    icon: <EventAvailableOutlinedIcon sx={{ 
      width: 35,
      height: 35,
      color: "#758694"
    }}
    />
  },
  {
    title: "Devolução enviada",
    borderColor: "#FFD93D",
    filterKey: "shipped",
    icon: <LocalShippingOutlinedIcon sx={{ 
      width: 35,
      height: 35, 
      color: "#FFD93D"
    }} 
    />
  },
  {
    title: "Entregue",
    borderColor: "#FFB347",
    filterKey: "delivered",
    icon: <NoCrashOutlinedIcon sx={{ 
      width: 35,
      height: 35,
      color: "#FFB347"
    }} 
    />
  },
  {
    title: "Pendentes de revisão",
    borderColor: "#31B7BC",
    filterKey: "to_review",
    icon: <RuleIcon sx={{ 
      width: 35,
      height: 35,
      color: "#31B7BC"
    }}
    />
  },
  {
    title: "Revisadas",
    borderColor: "#AFCB27",
    filterKey: "reviews",
    icon: <CheckCircleOutlineIcon sx={{ 
      width: 35,
      height: 35,
      color: "#AFCB27"
    }} 
    />
  },
  {
    title: "Não entregue",
    borderColor: "#FF0000",
    filterKey: "not_delivered",
    icon: <TaxiAlertOutlinedIcon sx={{ 
      width: 35,
      height: 35,
      color: "#FF0000"
    }} 
    />
  },
  {
    title: "Envio cancelado",
    borderColor: "#201E43",
    filterKey: "cancelled",
    icon: <RemoveRoadIcon sx={{ 
      width: 35,
      height: 35,
      color: "#201E43"
    }} 
    />
  },
];

export const failReasonStatus = (status) => {
  switch (status) {
    case "SRF2":
      return "O produto chegou avariado";
    case "SRF3":
      return "A devolução está incompleta";
    case "SRF4":
      return "O produto devolvido é diferente do que eu enviei";
    case "SRF5":
      return "O produto não está no pacote";
    case "SRF6":
      return "Reportar outro defeito no produto";
    case "SRF7":
      return "Ainda não chegou";
    default:
      return status;
  }
}


