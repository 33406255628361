import { httpConfig } from "src/config";

class ChatApi {
  constructor() {
    this.url = `${httpConfig.apiUrl[process.env.REACT_APP_ENV]}/v1/questions`;
  }
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
  };
  setNewToken(newToken) {
    if (newToken) {
      window.localStorage.setItem("accessToken", newToken);
    }
  }
  getQuestions(params = {}) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}?${new URLSearchParams(params)}`, {
        method: "GET",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    }, this.headers);
  }

  getTotalUnanswered() {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/count/unanswered`, {
        method: "GET",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    }, this.headers);
  }

  setSendMessage(body) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(this.url, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(body),
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  setReadMessage(messageId) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${messageId}`, {
        method: "PUT",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  getQuestion(messageId) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${messageId}`, {
        method: "GET",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  getQuestionId(questionId) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${questionId}/by-question`, {
        method: "GET",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  getMessagesUserAndAnnoucement(params) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(
        `${this.url}/messages/announcements?${new URLSearchParams(params)}`,
        {
          method: "GET",
          headers: this.headers,
        }
      );
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  setBlockUser(question_id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${question_id}/action/block`, {
        method: "PATCH",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  setHiddenMessage(question_id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${question_id}/action/hidden`, {
        method: "PATCH",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  setDeleteMessage(question_id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${question_id}/action/message`, {
        method: "DELETE",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      if (res.ok) {
        resolve({});
      } else {
        reject({});
      }
    });
  }

  getQuestionAnnoucement(question_id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${question_id}/annoucement`, {
        method: "GET",
        headers: this.headers,
      });
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  getMessagesByQuestion(question_id, type = "message") {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(
        `${this.url}/${question_id}/messages?type=${type}`,
        {
          method: "GET",
          headers: this.headers,
        }
      );
      this.setNewToken(res.headers.get("X-New-Token"));
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }
}

export default new ChatApi();
