export const permissionLevels = {
  noPermission: 0,
  viewOnly: 1,
  viewAndEdit: 2,
  viewAndCreate: 3,
  fullPermission: 4,
};

export const modules = {
  start: {
    path: "/auth/login",
    title: "",
  },
  login: {
    path: "/auth/login",
    title: "Login",
  },
  account: {
    path: "/v1/account",
    title: "Conta",
  },
  accountUsersNew: {
    path: "/v1/account/users/:id/new",
    title: "Nova conta",
  },
  accountUsersEdit: {
    path: "/v1/account/users/:id/edit",
    title: "Editar conta",
  }
};