import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import platformApi from "../api/platform";
import markeplacesApi from "../api/markeplaces";
import { toast } from "react-hot-toast";
import { api } from "src/services/api";

export const findAllPlatforms = createAsyncThunk(
  "platform/findAllPlatforms",
  async (_, thunkAPI) => {
    const platforms = await platformApi.getPlatforms();
    return platforms;
  }
);

export const findAllMarkeplaces = createAsyncThunk(
  "markeplaces/findAllMarkeplaces",
  async ({ customer_id, params = {} }, thunkAPI) => {
    const markeplaces = await markeplacesApi.getAccountsAll(
      customer_id,
      params
    );
    return markeplaces;
  }
);

export const createMarketplaceAccount = createAsyncThunk(
  "markeplaces/createMarketplaceAccount",
  async ({ body, setMarketplaces }, { dispatch, getState }) => {
    try {
   
      const marketplaces = await markeplacesApi.postAccountCreate(body);
   
      const responseMarketplaces = await api.get("/v1/account/marketplaces");
      setMarketplaces(responseMarketplaces.data.rows);
      toast.success("Conta adicionada com sucesso", {
        position: "bottom-right",
      });
      return marketplaces;
    } catch (error) {
      toast.error("Não foi possível realizar a criação da conta", {
        position: "bottom-right",
      });
    }
  }
);

const initialState = {
  platforms: [],
  accounts: [],
  paginate: {
    total: 0,
    page: 0,
    size: 10,
  },
  filter: {},
  loading: false,
  integrationToken: null,
};

export const slice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setIntegrationToken: (state, action) => {
      state.integrationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllPlatforms.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllPlatforms.fulfilled, (state, { payload }) => {
        state.platforms = payload;
      })
      .addCase(findAllPlatforms.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findAllMarkeplaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllMarkeplaces.fulfilled, (state, { payload }) => {
        state.accounts = payload.rows;
        state.paginate = {
          page:
            payload.count > 0 ? parseInt(payload.page) : state.paginate.page,
          size:
            payload.count > 0 ? parseInt(payload.size) : state.paginate.size,
          count: payload.count > 0 ? parseInt(payload.count) : 0,
        };
        state.loading = false;
      })
      .addCase(findAllMarkeplaces.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createMarketplaceAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMarketplaceAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createMarketplaceAccount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setFilter, setIntegrationToken },
} = slice;
