import React from 'react';
import { Avatar } from '@mui/material';

function ImageAvatar({ row = null, item = null }) {
  const [hasError, setHasError] = React.useState(false);

  const handleError = () => {
    setHasError(true);
  };

  const source = row || item;
  const acronym = source?.acronym?.toLowerCase();
  const avatar = source?.avatar;

  return (
    <Avatar
      variant="rounded"
      src={
        hasError
          ? require(`src/icons/integrations/${acronym}.png`)
          : avatar
      }
      onError={handleError}
     />
  );
}

export default ImageAvatar;
