import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "react-feather";

const StatusModal = ({
  open,
  onClose,
  selectedStatus,
  handleStatusChange,
  handleUpdateStatus,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Status Weello</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="status-label">Status Weello</InputLabel>
          <Select
            labelId="status-label"
            value={selectedStatus}
            onChange={handleStatusChange}
            label="Status Weello"
          >
            <MenuItem value="not_recived">Não recebido</MenuItem>
            <MenuItem value="parcial_recived">Recebimento Parcial</MenuItem>
            <MenuItem value="received_divergence">Recebimento com divergência</MenuItem>
            <MenuItem value="received_damaged">Recebimento com Avaria</MenuItem>
            <MenuItem value="completed">Concluída</MenuItem>
            <MenuItem value="cancelled">Cancelada</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          disabled={loading}
          onClick={handleUpdateStatus}
          variant="contained"
          color="primary"
          loading={loading}
        >
          <SvgIcon sx={{ mr: 1 }}>
            <Save />
          </SvgIcon>
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StatusModal;
