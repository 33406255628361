import { httpConfig } from "src/config";

const STORAGE_KEY = "accessToken";
const baseURL = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const ReturnApi = {
  findAll: async ({ page = 0, size = 10, query = "", noPagination = true, filter, account, sort = "asc", claim_id }) => {
    const params = {
      page,
      size,
      query,
      noPagination,
      filter,
      account,
      sort,
      claim_id
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${baseURL}/v1/claims/returns?${new URLSearchParams(params)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  findOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/returns/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default ReturnApi;