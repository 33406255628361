import { createContext, useContext, useState, useMemo } from "react";

const NotificationCounterContext = createContext();

export const NotificationCounterProvider = ({ children }) => {
  const [notificationCounter, setNotificationCounter] = useState({
    questions: 0,
    messages: 0,
    claims: 0,
    chats: 0,
  });

  const notificationsTotalCount = () => {
    return (
      +notificationCounter['questions'] +
      +notificationCounter['messages'] +
      +notificationCounter['claims'] +
      +notificationCounter['chats']
    );
  };

  const contextValue = useMemo(() => {
    return { notificationCounter, setNotificationCounter, notificationsTotalCount };
  }, [notificationCounter]);

  return (
    <NotificationCounterContext.Provider value={contextValue}>
      {children}
    </NotificationCounterContext.Provider>
  );
};

export const useNotificationCounter = () => {
  const context = useContext(NotificationCounterContext);

  return context;
};


