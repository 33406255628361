import { baseCoreUrl } from "./http";
import { httpConfig } from "../config";

const STORAGE_KEY = "accessToken";
const baseURL = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const UserApi = {
  /**
   * Faz um GET com id do modulo para obter as permissões do usuário
   * @param {Number} module_id
   * @returns
   */
  getPermission: async (module_id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/users/permissions/modules/${module_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * faz um GET pelo subbrupo dos parametros e carrega todos os parametros que o
   * usuário está habilitado ou cadastrado.
   * @param {String} subgroup
   * @returns
   */
  getParameters: async (params) => {
    let urlParams = new URLSearchParams();
    for (const key in params) {
      urlParams.append("params", params[key]);
    }
    return new Promise((resolve, reject) => {
      fetch(
        `${baseURL}/v1/users/permissions/parameters?${urlParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findAll: async ({ page = 0, size = 10, query = "", noPagination = true }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseURL}/v1/users?${new URLSearchParams({
          page,
          size,
          query,
          noPagination,
        })}`,
        // `${url}/v2/core/users/no-pagination?${new URLSearchParams({ query })}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/users/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOne: async (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/users/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createOne: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/v1/users/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default UserApi;
