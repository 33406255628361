import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { Box, Typography, Divider, SvgIcon, Avatar } from "@mui/material";
import { PieChart } from '@mui/x-charts/PieChart';
import QuestionIcon from "src/components/conversations/question-icon";
import MessageIcon from "src/components/conversations/message-icon";
import ClaimIcon from "src/components/conversations/claim-icon";
import ChatIcon from "src/components/conversations/chat-icon";

const concluidas = '#31B7BC';
const naoRespondidas = '#C51616';
const naoLidas = '#FFDA39';
const lidas = '#AFCB27';

const iconMap = {
  Perguntas: <QuestionIcon />,
  Mensagens: <MessageIcon />,
  Reclamações: <ClaimIcon />,
  Chats: <ChatIcon />
};

const imageMap = {
  Perguntas: "/assets/mktplc/mercadolivre.png",
  Mensagens: "/assets/mktplc/mercadolivre.png",
  Reclamações: "/assets/mktplc/mercadolivre.png",
  Chats: "/assets/mktplc/shopee.png"
};

const COLORS = [naoLidas, naoRespondidas, concluidas, lidas];

function StatisticsCard({ data, title }) {
  const chartData = data.filter(entry => entry.name !== "Total").map((entry, index) => ({
    label: entry.name,
    value: entry.value,
    color: COLORS[index % COLORS.length]
  }));

  return (
    <Card style={{  borderRadius: '10px', padding: '20px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <SvgIcon fontSize="small">
              {iconMap[title]}
            </SvgIcon>
          <Typography variant="h6" style={{ color: '#333', marginLeft: '10px' }}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
        <Avatar src={imageMap[title]} variant="rounded" alt={title} style={{ height: '40px' }} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <PieChart
          series={[
            {
              outerRadius: 100,
              innerRadius: 60,
              data: chartData,
              fill: (item) => item.data.color,
            },
          ]}
          height={250}
          width={100}
          slotProps={{
            legend: { hidden: true },
          }}
        />

        <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" pl={2}>
           <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#333' }}>Total: {data.find(entry => entry.name === "Total")?.value || 0}</Typography>
          </Box>
          <Divider style={{ width: '100%', margin: '10px 0' }} />
          
            <Box display="flex" alignItems="center" mt={1}>
              <Box bgcolor={"#FFDA39"} width={16} height={16} mr={1} />
              <Typography variant="body2" color="textSecondary">Não Lidas: {data.find(entry => entry.name === "Não Lidas")?.value || 0}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <Box bgcolor={"#C51616"} width={16} height={16} mr={1} />
              <Typography variant="body2" color="textSecondary">Não Respondidas: {data.find(entry => entry.name === "Não Respondidas")?.value || 0}</Typography>
            </Box>
            <Divider style={{ width: '100%', margin: '10px 0' }} />

            <Box display="flex" alignItems="center" mt={1}>
              <Box bgcolor={"#AFCB27"} width={16} height={16} mr={1} />
              <Typography variant="body2" color="textSecondary">Lidas: {data.find(entry => entry.name === "Lidas")?.value || 0}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <Box bgcolor={"#31B7BC"} width={16} height={16} mr={1} />
              <Typography variant="body2" color="textSecondary">Respondidas: {data.find(entry => entry.name === "Respondidas")?.value || 0}</Typography>
            </Box>
        
            <Divider style={{ width: '100%', margin: '10px 0' }} />
         
        </Box>
      </Box>
    </Card>
  );
}

StatisticsCard.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default StatisticsCard;
