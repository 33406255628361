import { appIdConfig, httpConfig } from "src/config";
import { baseCoreUrl } from "../http";

const url = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const STORAGE_KEY = "accessToken";

class PlatformApi {
  constructor() {
    this.url = url;
    this.headers = () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    });
    appIdConfig[process.env.REACT_APP_ENV];
  }
  getPlatforms(params = {}) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(
        `${
          this.url
        }/v1/application/${this.application()}/platforms?${new URLSearchParams(
          params
        )}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }
}

export default new PlatformApi();
