import { httpConfig } from "src/config";
import { baseCoreUrl } from "./http";

const url = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const STORAGE_KEY = "accessToken";

const UserGroupsApi = {
  findAll: async ({ page = 0, size = 10, query = "" }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${url}/v1/groups/users?${new URLSearchParams({
          page,
          size,
          query,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "accessToken"
            )}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/groups/users/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOne: async (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/groups/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createOne: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/groups/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/groups/users/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default UserGroupsApi;
