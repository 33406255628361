import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { Scrollbar } from "src/components/scrollbar";
import { useRouter } from "src/hooks/use-router";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { api } from "src/services/api";
import { useNotificationCounter } from "src/contexts/notification-counter-context";
import CircularProgress from "@mui/material/CircularProgress";
import { useMobileMessage } from "src/contexts/mobile-message-context";
import { useChatKey } from "src/contexts/chat-key-context";

moment.locale("pt-br");

const RenderContent = ({ notification, onClose, setMobileMessage }) => {
  const router = useRouter();
  const { setChatKey } = useChatKey();

  function onNavigateQuestion(e) {
    api.patch(`/v1/conversation/set/read`, { conversationIds: [ notification.id ] });
    setChatKey(notification.id);
    router.push(`/v1/${notification.type}?key=${notification.id}`);
    onClose();
    setMobileMessage(true);
  }

  return (
    <>
      <ListItemAvatar
        sx={{ mt: 0.5, cursor: "pointer" }}
        onClick={onNavigateQuestion}
      >
        <Avatar
          variant="rounded"
          src={`/assets/mktplc/${notification.platform_name?.toLowerCase()}.png`}
        />
      </ListItemAvatar>
      <ListItemText
        onClick={onNavigateQuestion}
        primary={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ mr: "5px" }} variant="subtitle2" align="left">
              {notification.customerName}
            </Typography>
            <Typography
              variant="text"
              disableElevation
              sx={{
                display: "flex",
                padding: 0,
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "regular",
                color: "text.secondary",
              }}
            >
              {notification?.message}
            </Typography>
          </Box>
        }
        secondary={
          <Typography color="text.secondary" variant="caption">
            {moment(notification.updated_at).fromNow()}
          </Typography>
        }
        sx={{ my: 0, cursor: "pointer" }}
      />
    </>
  );
};

export const NotificationsPopover = (props) => {
  const {
    anchorEl,
    onClose,
    onMarkAllAsRead,
    onRemoveOne,
    open = false,
    ...other
  } = props;

  const [ notifications, setNotifications ] = useState([]);
  const { notificationCounter, setNotificationCounter, notificationsTotalCount } =
    useNotificationCounter();
  const [loading, setLoading] = useState(false);
  const [loadingCleaning, setLoadingCleaning] = useState(false);
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
  const { setMobileMessage } = useMobileMessage();

  useEffect(() => {
    async function loadNotifications() {
      try {
        setLoading(true);
        const response = await api.get("/v1/conversation/notifications");
        setNotifications(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (open) {
      loadNotifications();
    }
  }, [open]);
  const clearNotifications = async () => {
    try {
      setLoadingCleaning(true);
      await api.patch("/v1/conversation/notifications");
      setNotificationCounter((prevState) => ({
        ...prevState,
        messages: 0,
        chats: 0,
        claims: 0
      }));
      setNotifications([])
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCleaning(false);
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: desktopUp ? "right" : "center",
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}
    >
      <Stack
        alignItems="baseline"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          Notificações
        </Typography>
        {notificationsTotalCount() == 0 && !loading && (
          <Typography color="text.secondary" variant="caption">
            Você não possui notificação
          </Typography>
        )}

        {notificationsTotalCount() > 0 && (
          <IconButton
            disableRipple={true}
            disabled={loadingCleaning || loading}
            sx={{
              padding: 0,

              color: "inherit",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.main",
              },
            }}
            onClick={clearNotifications}
          >
            <Typography color="inherit" variant="caption">
              Limpar tudo
            </Typography>
          </IconButton>
        )}
      </Stack>
      <Scrollbar sx={{ maxHeight: 400 }}>
        <List disablePadding>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 1 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {notifications.length > 0 && (
                <>
                  {notifications?.map((notification) => (
                    <ListItem
                      divider
                      key={notification.id}
                      sx={{
                        alignItems: "flex-start",
                        background:
                          notification.type === "claims"
                            ? "rgba(240, 69, 56, 0.06)"
                            : undefined,
                        "&:hover": {
                          backgroundColor: "action.hover",
                        },
                        "& .MuiListItemSecondaryAction-root": {
                          top: "24%",
                        },
                      }}
                    >
                      <RenderContent
                        notification={notification}
                        onClose={onClose}
                        setMobileMessage={setMobileMessage}
                      />
                    </ListItem>
                  ))}
                </>
              )}
            </>
          )}
        </List>
      </Scrollbar>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
