import { httpConfig } from "src/config";
import { getAbbreviation } from "src/utils/abbreviation-text";

class QuickAnswerApi {
  constructor() {
    this.url = `${httpConfig.apiUrl[process.env.REACT_APP_ENV]}/v1/quick/answers`;
    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    };
  }

  getQuickAnswers() {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(this.url, {
        method: "GET",
        headers: this.headers,
      });
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  setQuickAnswers(body) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(this.url, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(body),
      });
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  delQuickAnswers(id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${id}`, {
        method: "DELETE",
        headers: this.headers,
      });
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }
}

export default new QuickAnswerApi();
