import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { AnchorLink } from "../helperDropdown.styles";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { neutral, primary } from "src/theme/colors";

export const CentralDuvidasListItem = () => {
    const HELPER_BASE_URL = "https://ajuda.weesu.com.br";
    return (
        <AnchorLink href={`${HELPER_BASE_URL}`} target="_blank">
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  pt: 0,
                  pb: 0,
                  "&:hover": {
                    backgroundColor: primary["alpha8"],
                  },
                }}
              >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                  <HelpCenterIcon sx={{color: neutral[500]}} />
                  <ListItemText primary="Central de dúvidas" />
                </Stack>
              </ListItemButton>
            </ListItem>
        </AnchorLink>
    )
}