import React from "react";
import { List, ListItem, ListItemText, Button, Typography, Skeleton } from "@mui/material";

const StageTwo = ({ loading, failReasons, handleReasonSelect, handleBack }) => (
  <>
    <Typography
      id="modal-modal-title"
      variant="h4"
      component="h2"
      textAlign="center"
      sx={{ mt: 1, mb: 1 }}
    >
      Como o produto chegou para você?
    </Typography>
    {loading ? (
      <List>
        {[1, 2, 3, 4, 5].map((item) => (
          <ListItem key={item}>
            <Skeleton variant="rectangular" width="100%" height={48} />
          </ListItem>
        ))}
      </List>
    ) : (
      <List>
        {failReasons.map((reason) => (
          <ListItem button key={reason.id} onClick={() => handleReasonSelect(reason)}>
            <ListItemText primary={reason.detail} />
          </ListItem>
        ))}
      </List>
    )}
    <Button onClick={handleBack} variant="contained" sx={{ mt: 2 }}>
      Voltar
    </Button>
  </>
);

export default StageTwo;
