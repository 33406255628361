import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  SvgIcon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "react-feather";

const DescriptionModal = ({
  open,
  onClose,
  selectedDescription,
  handleDescriptionChange,
  handleUpdateDescription,
  loading
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {selectedDescription ? "Editar Descrição" : "Adicionar Descrição"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          label="Descrição"
          value={selectedDescription}
          onChange={handleDescriptionChange}
          multiline
          rows={4}
          helperText={`${(selectedDescription || "").length}/255`}
          inputProps={{ maxLength: 255 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          disabled={loading}
          onClick={handleUpdateDescription}
          variant="contained"
          color="primary"
          loading={loading}
        >
          <SvgIcon sx={{ mr: 1 }}>
            <Save />
          </SvgIcon>
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionModal;
