export const fetchAddress = async (zip) => {
    try {
       const response = await fetch(`https://viacep.com.br/ws/${zip}/json/`);
       if (response.ok) {
         const data = await response.json();
         if (!data.error) {
           return {
             address: data.logradouro,
             district: data.bairro,
             city: data.localidade,
             state: data.uf,
           };
         }
       }
    } catch (error) {
       console.error("Falha ao buscar o CEP:", error);
    }
    return null;
   };