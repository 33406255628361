import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SplashScreen } from "./components/splash-screen";
import { Toaster } from "./components/toaster";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import { AuthConsumer, AuthProvider } from "./contexts/auth-context";
import { useNprogress } from "./hooks/use-nprogress";
import { routes } from "./routes";
import { store } from "./store";
import { createTheme } from "./theme";
// Remove if react-quill is not used
import "react-quill/dist/quill.snow.css";
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
import "mapbox-gl/dist/mapbox-gl.css";
// Remove if locales are not used
import "./locales/i18n";
import { SettingsDrawer } from "./components/settings/settings-drawer";
import { OpenProvider } from "./contexts/open-context";
import { MobileMessageProvider } from "./contexts/mobile-message-context";
import { UserProvider } from "./contexts/user-context";
import { SignatureProvider } from "./contexts/signature-context";
import { NotificationCounterProvider } from "./contexts/notification-counter-context";
import { FiltersProvider } from "./contexts/filters-context";
import { SyncProvider } from "./contexts/sync-context";
import { AccountsProvider } from "./contexts/account-context";
import RegistrationPopup from "./sections/dashboard/account/user-registration-address";
import { useEffect } from "react";
import { genereateToken } from "./notifications/firebase";
import { ChangePassword } from "./sections/dashboard/account/account-change-password";
import { ChangePasswordProvider } from "./contexts/change-password-context";
import { SocketProvider } from "./contexts/socket-context";

export const App = () => {
  useNprogress();
  const element = useRoutes(routes);

  useEffect(() => {
    const getToken = async () => {
      await genereateToken();
    };
    getToken();
  }, []);

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ChangePasswordProvider>
          <UserProvider>
            <AuthProvider>
              <AccountsProvider>               
                <SocketProvider>
                  <NotificationCounterProvider>
                      <SyncProvider>
                        <AuthConsumer>
                          {(auth) => (
                            <SettingsProvider>
                              <SettingsConsumer>
                                {(settings) => {
                                  const theme = createTheme({
                                    contrast: settings.contrast,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes:
                                      settings.responsiveFontSizes,
                                  });

                                  // Prevent guards from redirecting
                                  const showSlashScreen = !auth.isInitialized;

                                  return (
                                    <ThemeProvider theme={theme}>
                                      <Helmet>
                                        <meta
                                          name="color-scheme"
                                          content={settings.paletteMode}
                                        />
                                        <meta
                                          name="theme-color"
                                          content={theme.palette.neutral[900]}
                                        />
                                      </Helmet>
                                      <CssBaseline />
                                      {showSlashScreen ? (
                                        <SplashScreen />
                                      ) : (
                                        <SignatureProvider>
                                          <ChangePassword />
                                          <RegistrationPopup />
                                          <OpenProvider>
                                            <MobileMessageProvider>
                                              {element}
                                            </MobileMessageProvider>
                                          </OpenProvider>

                                          <SettingsDrawer
                                            canReset={settings.isCustom}
                                            onClose={settings.handleDrawerClose}
                                            onReset={settings.handleReset}
                                            onUpdate={settings.handleUpdate}
                                            open={settings.openDrawer}
                                            values={{
                                              colorPreset: settings.colorPreset,
                                              contrast: settings.contrast,
                                              direction: settings.direction,
                                              paletteMode: settings.paletteMode,
                                              responsiveFontSizes:
                                                settings.responsiveFontSizes,
                                              stretch: settings.stretch,
                                              layout: settings.layout,
                                              navColor: settings.navColor,
                                            }}
                                          />
                                        </SignatureProvider>
                                      )}
                                      <Toaster />
                                    </ThemeProvider>
                                  );
                                }}
                              </SettingsConsumer>
                            </SettingsProvider>
                          )}
                        </AuthConsumer>
                      </SyncProvider>                    
                  </NotificationCounterProvider>
                </SocketProvider>               
              </AccountsProvider>
            </AuthProvider>
          </UserProvider>
        </ChangePasswordProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
