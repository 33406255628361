import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "src/layouts/dashboard/horizontal-layout";

export const RightDrawer = ({ children, ...otherProps }) => {
  const theme = useTheme();
  let { topNavHeight, sectionHeight } = useContext(LayoutContext);
  if (topNavHeight === undefined) topNavHeight = 0;
  if (sectionHeight === undefined) sectionHeight = 0;
  let totalHeight = topNavHeight + sectionHeight;
  
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      ModalProps={{
        BackdropProps: {
          invisible: false,
        },
      }}
      PaperProps={{
        sx: {
          [theme.breakpoints.down("desktop")]: {
            top: `${totalHeight}px`,
            height: `calc(100% - ${totalHeight}px)`,
            maxHeight: `calc(100% - ${totalHeight}px)`,
            position: 'fixed',
            width: "100vw"
          },
          [theme.breakpoints.up("desktop")]: {
            width: 500
          },
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      {...otherProps}
    >
      {children}
    </Drawer>
  );
};
