import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import returnapi from "src/api/return.js";

export const findAllReturns = createAsyncThunk(
  "returns/findAllReturns",
  async ({ query, page, size, filter, account, sort, claim_id }, thunkAPI) => {
    const returns = await returnapi.findAll({ page, size, query, filter, account, sort, claim_id });
    return returns;
  }
);

export const findOneReturn = createAsyncThunk(
  "returns/findOneReturn",
  async (id, thunkAPI) => {
    const returns = await returnapi.findOne(id);
    return returns;
  }
);

const initialState = {
  return: {},
  returns: [],
  paginate: {
    page: 0,
    size: 10,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    setReturn: (state, action) => {
      state.return = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllReturns.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findAllReturns.fulfilled, (state, { payload }) => {
        state.returns = payload.rows;
        state.paginate = {
          page: payload.count > 0 ? parseInt(payload.page) : state.paginate.page,
          size: payload.count > 0 ? parseInt(payload.size) : state.paginate.size,
          count: payload.count > 0 ? parseInt(payload.count) : 0,
        };
        state.loading = false;
      })
      .addCase(findAllReturns.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findOneReturn.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneReturn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.return = payload;
      })
      .addCase(findOneReturn.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setReturn, setPaginate },
} = slice;