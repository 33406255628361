import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Skeleton,
  Grid,
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { useSelector, useDispatch } from "react-redux";
import { findAllReturns } from "src/slices/returns";
import { useSearchParams } from "react-router-dom";
import { useFilters } from "src/contexts/filters-context";
import { useUser } from "src/contexts/user-context";
import FilterBar from "./components/filter-bar-component.js";
import ReturnRow from "./components/table-row-component.js";
import MobileReturnCard from "./components/mobile-return.js";
import { Scrollbar } from "src/components/scrollbar";
import UploadFileModal from "./input-file-modal";
import ReturnAttachmentList from "./attachment-list-modal";
import ReviewModal from "./review-modal";
import DrawerDetail from "./drawer-detail";
import { ListObjectsV2Command } from "@aws-sdk/client-s3";
import { api } from "src/services/api";
import toast from "react-hot-toast";
import StatusModal from "./components/status-weello-component.js";
import DescriptionModal from "./components/description-component.js";
import { Sort } from "src/sections/dashboard/chat/sort";
import { s3 } from "src/utils/storage-provider.js";
import { FilterAltOff } from "@mui/icons-material";
import CounterCard from "./components/card-counter-component.js";
import { cardsDataExemple } from "src/utils/utils-returns.js";
import CounterCardMobile from "./components/card-counter-mobile-component.js";

const ReturnTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const claimId = searchParams.get("claimId");
  const { returns, paginate, loading } = useSelector((state) => state.returns);
  const dispatch = useDispatch();
  const { filters, setFilters, reset } = useFilters();
  const { user } = useUser();
  const desktopDown = useMediaQuery((theme) =>
    theme.breakpoints.down("desktop")
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openInputFileModal, setOpenInputFileModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openAttachmentListModal, setOpenAttachmentListModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsExist, setAttachmentsExist] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedReturnId, setSelectedReturnId] = useState(null);
  const [selectedReturnItem, setSelectedReturnItem] = useState(null);
  const [localReturns, setLocalReturns] = useState(returns);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedDetailItem, setSelectedDetailItem] = useState(null);
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [selectedFilterKey, setSelectedFilterKey] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const fetchCardData = async () => {
    try {
      const response = await api.get("/v1/claims/cards");
      const data = response.data;
  
      const updatedCardsData = cardsDataExemple.map((card) => {
        const backendData = data.find((item) => item.statusMP === card.filterKey);
        return backendData
          ? { ...card, value: backendData.count }
          : { ...card, value: 0 };
      });
  
      setCardsData(updatedCardsData);  
     
      const selectedCard = data.find((item) => item.statusMP === selectedFilterKey);
      setTotalCount(selectedCard ? selectedCard.count : 0);
    } catch (error) {
      console.error("Erro ao buscar dados dos cards:", error);
      toast.error("Erro ao carregar dados dos cards");
    }
  };
  
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleCardClick = (filterKey) => {
    reset();
    setSelectedFilterKey(filterKey);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter: filterKey,
      query: "",
    }));
  };
  const updateReturnData = async () => {
    dispatch(
      findAllReturns({
        page: paginate.page,
        size: paginate.size,
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.order,
        claim_id: claimId,
      })
    );
  };

  useEffect(() => {
    fetchCardData();
    dispatch(
      findAllReturns({
        page: paginate.page,
        size: paginate.size,
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.order,
        claim_id: claimId,
      })
    );
  }, [dispatch, filters, paginate.page, paginate.size]);
  
  useEffect(() => {
    const checkAttachments = async () => {
      const attachmentsStatus = {};
      for (const returnItem of returns) {
        const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnItem.id}/`;
        const params = {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Prefix: attachmentsPath,
        };
        try {
          const data = await s3.send(new ListObjectsV2Command(params));
          attachmentsStatus[returnItem.id] =
            data.Contents && data.Contents.length > 0;
        } catch (error) {
          console.error("Erro ao verificar anexos:", error);
          attachmentsStatus[returnItem.id] = false;
        }
      }
      setAttachmentsExist(attachmentsStatus);
    };

    if (returns.length > 0) {
      checkAttachments();
    }
  }, [returns, user.customer]);

  const updateAttachmentStatus = async (returnId) => {
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnId}/`;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Prefix: attachmentsPath,
    };
    try {
      const data = await s3.send(new ListObjectsV2Command(params));
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnId]: data.Contents && data.Contents.length > 0,
      }));
    } catch (error) {
      console.error("Erro ao verificar anexos:", error);
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnId]: false,
      }));
    }
  };

  useEffect(() => {
    setLocalReturns(returns);
  }, [returns]);

  useEffect(() => {
    if (claimId) {
      setSearchParams({});
    }
  }, [filters, claimId, setSearchParams]);

  const onChangeFilter = useCallback(
    (params) => {
      dispatch(findAllReturns(params));
    },
    [dispatch]
  );

  const onRowsPerPageChanged = (event) => {
    dispatch(
      findAllReturns({
        page: 0,
        size: parseInt(event.target.value),
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.sort,
      })
    );
  };

  const onPageChange = (_, newPage) => {
    dispatch(
      findAllReturns({
        page: newPage,
        size: paginate.size,
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.sort,
      })
    );
  };

  const onKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      onChangeFilter({
        page: 0,
        size: paginate.size,
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.sort,
      });
    }
  };

  const handleResetFilters = () => {
    reset();
    setFilters((prevFilters) => ({
      ...prevFilters,
      query: "",
    }));
    setSelectedFilterKey("all");
    if (claimId) {
      setSearchParams({});
    }
  };

  const handleOpenStatusModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedStatus(returnItem.statusWeello);
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    try {
      setLoadingComponents(true);
      await api.put(
        `/v1/claims/update-statusWeello-description/${selectedReturnId}`,
        {
          statusWeello: selectedStatus,
        }
      );
      toast.success("Status Weello atualizado com sucesso");
      const updatedReturns = localReturns.map((item) =>
        item.id === selectedReturnId
          ? { ...item, statusWeello: selectedStatus }
          : item
      );
      setLocalReturns(updatedReturns);
      handleCloseStatusModal();
    } catch (error) {
      toast.error("Erro ao atualizar status Weello", {
        position: "bottom-right",
      });
    } finally {
      setLoadingComponents(false);
    }
  };

  const handleOpenDescriptionModal = async (returnItem) => {
    setSelectedReturnId(returnItem.id);
    try {
      const response = await api.get(
        `/v1/claims/get-description/${returnItem.id}`
      );
      setSelectedDescription(response.data.description);
    } catch (error) {
      toast.error("Erro ao carregar descrição", {
        position: "bottom-right",
      });
    }
    setOpenDescriptionModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setOpenDescriptionModal(false);
  };

  const handleDescriptionChange = (event) => {
    setSelectedDescription(event.target.value);
  };

  const handleUpdateDescription = async () => {
    try {
      setLoadingComponents(true);
      await api.put(
        `/v1/claims/update-statusWeello-description/${selectedReturnId}`,
        {
          description: selectedDescription,
        }
      );
      toast.success("Descrição atualizada com sucesso");
      const updatedReturns = localReturns.map((item) =>
        item.id === selectedReturnId
          ? { ...item, description: selectedDescription }
          : item
      );
      setLocalReturns(updatedReturns);
      handleCloseDescriptionModal();
    } catch (error) {
      toast.error("Erro ao atualizar descrição", {
        position: "bottom-right",
      });
    } finally {
      setLoadingComponents(false);
    }
  };

  const handleOpenInputFileModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenInputFileModal(true);
  };

  const handleCloseInputFileModal = () => {
    setOpenInputFileModal(false);
  };

  const handleOpenReviewModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
  };

  const handleOpenAttachmentListModal = async (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnItem.id}/`;

    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Prefix: attachmentsPath,
      };

      const data = await s3.send(new ListObjectsV2Command(params));
      const s3Attachments = data.Contents
        ? data.Contents.map((item) => ({
            name: item.Key.split("/").pop(),
            url: `https://assets-s3.weesutech.com.br/${item.Key}`,
          }))
        : [];

      setAttachments(s3Attachments);
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnItem.id]: s3Attachments.length > 0,
      }));
      setOpenAttachmentListModal(true);
    } catch (error) {
      console.error("Erro ao carregar anexos:", error);
      toast.error("Erro ao carregar anexos", {
        position: "bottom-right",
      });
    }
  };

  const handleCloseAttachmentListModal = () => {
    setOpenAttachmentListModal(false);
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);

    if (updatedAttachments.length === 0) {
      setAttachmentsExist((prev) => ({
        ...prev,
        [selectedReturnId]: false,
      }));
    }
  };

  const handleOpenDetailDrawer = (returnItem) => {
    setSelectedDetailItem(returnItem);
    setOpenDetailDrawer(true);
  };

  const handleCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };
  
  const hasFulfillment = returns.some(
    (item) => item?.announcement?.logistic_type === "fulfillment"
  );
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        mt: desktopDown ? 2 : 5,
      }}
    >
      {desktopDown ? (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "100vh",
            height: "21.25em",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ padding: 2, width: "100%" }}
            display="flex"
            justifyContent="space-around"
          >
            {cardsData.map((card, index) => (
              <Grid item xs={5} key={index}>
                <CounterCardMobile
                  title={card.title}
                  value={card.value}
                  borderColor={card.borderColor}
                  icon={card.icon}
                  onClick={() => handleCardClick(card.filterKey)}
                  isSelected={selectedFilterKey === card.filterKey}
                />
              </Grid>
            ))}
          </Grid>
          <Stack
            alignContent="center"
            alignSelf="center"
            spacing={1}
            sx={{ mr: 2, ml: 2 }}
          >
            <TextField
              width="60px"
              label="Pesquisar devolução"
              value={filters.query || ""}
              onChange={(e) =>
                setFilters({ ...filters, query: e.target.value })
              }
              onKeyDown={onKeyDownSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{ padding: 2 }}
            width="100%"
          >
            <Sort />
            <IconButton onClick={handleResetFilters}>
              <Tooltip title="Remover filtros">
                <FilterAltOff />
              </Tooltip>
            </IconButton>
          </Stack>
          <Box sx={{ padding: 2 }}>
            {localReturns.map((item, index) => (
              <MobileReturnCard
                key={index}
                item={item}
                index={index}
                expandedRow={expandedRow}
                handleExpandClick={handleExpandClick}
                attachmentsExist={attachmentsExist}
                handleOpenAttachmentListModal={handleOpenAttachmentListModal}
                handleOpenDetailDrawer={handleOpenDetailDrawer}
                handleOpenDescriptionModal={handleOpenDescriptionModal}
                handleOpenStatusModal={handleOpenStatusModal}
                handleOpenInputFileModal={handleOpenInputFileModal}
                handleOpenReviewModal={handleOpenReviewModal}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Card sx={{ width: "90%", height: "auto" }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <CardContent>
               <Grid container spacing={4} sx={{ mt: 0.5, mb: 4 }}>
                  {cardsData.map((card, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <CounterCard
                        title={card.title}
                        value={card.value}
                        borderColor={card.borderColor}
                        icon={card.icon}
                        onClick={() => handleCardClick(card.filterKey)}
                        isSelected={selectedFilterKey === card.filterKey}
                      />
                    </Grid>
                  ))}
                  </Grid>
                 <FilterBar
                  filters={filters}
                  setFilters={setFilters}
                  onKeyDownSearch={onKeyDownSearch}
                  handleResetFilters={handleResetFilters}
                />
              </CardContent>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Anúncio</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Código do Pedido</TableCell>
                        <TableCell>Data/Hora</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Qtde</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Comprador</TableCell>
                        <TableCell>Conta</TableCell>
                        {hasFulfillment && (
                          <TableCell />
                        )
                        }
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading
                        ? Array.from({ length: 3 }).map((_, index) => (
                            <TableRow key={index}>
                              <TableCell colSpan={12}>
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height={50}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        : localReturns.map((row, index) => (
                            <ReturnRow
                              key={index}
                              row={row}
                              index={index}
                              expandedRow={expandedRow}
                              handleExpandClick={handleExpandClick}
                              attachmentsExist={attachmentsExist}
                              handleOpenAttachmentListModal={
                                handleOpenAttachmentListModal
                              }
                              handleOpenDetailDrawer={handleOpenDetailDrawer}
                              handleOpenDescriptionModal={
                                handleOpenDescriptionModal
                              }
                              handleOpenStatusModal={handleOpenStatusModal}
                              handleOpenInputFileModal={
                                handleOpenInputFileModal
                              }
                              handleOpenReviewModal={handleOpenReviewModal}
                              hasFulfillment={hasFulfillment}
                            />
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalCount}  // Usando a contagem total do filtro 'all'
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChanged}
                  page={paginate?.page}
                  rowsPerPage={paginate?.size}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Devoluções por página"
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} de ${count}`;
                  }}
                />
              </Scrollbar>
            </Grid>
          </Grid>
        </Card>
      )}

      <StatusModal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        handleUpdateStatus={handleUpdateStatus}
        loading={loadingComponents}
      />
      <DescriptionModal
        open={openDescriptionModal}
        onClose={handleCloseDescriptionModal}
        selectedDescription={selectedDescription}
        handleDescriptionChange={handleDescriptionChange}
        handleUpdateDescription={handleUpdateDescription}
        loading={loadingComponents}
      />
      <UploadFileModal
        open={openInputFileModal}
        onClose={handleCloseInputFileModal}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
      />
      <ReturnAttachmentList
        open={openAttachmentListModal}
        onClose={handleCloseAttachmentListModal}
        attachments={attachments}
        onRemove={handleRemoveAttachment}
        selectedReturnId={selectedReturnId}
        returnItem={selectedReturnItem}
      />
      <ReviewModal
        open={openReviewModal}
        onClose={handleCloseReviewModal}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
        updateReturnData={updateReturnData}
      />
      <DrawerDetail
        open={openDetailDrawer}
        onClose={handleCloseDetailDrawer}
        details={selectedDetailItem?.details || []}
        shippingHistory={selectedDetailItem?.shipping_history || []}
        reviewStatus={selectedDetailItem}
      />
    </Box>
  );
};

export default ReturnTable;
