import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Avatar,
  Skeleton,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useUser } from "src/contexts/user-context";
import DropzoneComponent from "src/components/dropzone-component";
import { ListObjectsV2Command, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { s3 } from "src/utils/storage-provider";
import toast from "react-hot-toast";

const UploadFileModal = ({ open, onClose, selectedReturnId, updateAttachmentStatus }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [uploadingFiles, setUploadingFiles] = useState([]);

  useEffect(() => {
    if (open) {
      fetchStoredFiles();
    }
  }, [open]);

  const fetchStoredFiles = async () => {
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${selectedReturnId}/`;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Prefix: attachmentsPath,
    };
    try {
      setLoading(true);
      const data = await s3.send(new ListObjectsV2Command(params));
      const s3Files = data.Contents
        ? data.Contents.map((item) => ({
            name: item.Key.split("/").pop(),
            url: `https://assets-s3.weesutech.com.br/${item.Key}`,
            key: item.Key,
          }))
        : [];
      setFiles(s3Files);
    } catch (error) {
      toast.error("Erro ao carregar anexos", { position: "bottom-right" });
      console.error("Erro ao carregar anexos do S3:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  const handleRemoveFile = async (key) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key,
    };
    try {
      await s3.send(new DeleteObjectCommand(params));
      setFiles((prevFiles) => prevFiles.filter((file) => file.key !== key));
      toast.success("Arquivo removido com sucesso", { position: "bottom-right" });
      await updateAttachmentStatus(selectedReturnId);
    } catch (error) {
      toast.error("Erro ao remover arquivo", { position: "bottom-right" });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Adicionar anexos</DialogTitle>
      <DialogContent>
        <DropzoneComponent
          s3Files={files}
          setS3Files={setFiles}
          user={user}
          selectedReturnId={selectedReturnId}
          setLoading={setLoading}
          allowVideos={true}
          updateAttachmentStatus={updateAttachmentStatus}
          setUploadingFiles={setUploadingFiles}
        />
        {loading ? (
          <Box mt={2} display="flex" flexWrap="wrap">
            {[...Array(8).keys()].map((item) => (
              <Skeleton key={item} variant="rounded" width={50} height={50} sx={{ margin: 1 }} />
            ))}
          </Box>
        ) : (
          files.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2">Arquivos já armazenados:</Typography>
              <Box display="flex" flexWrap="wrap" marginTop={2}>
                {files.map((file, index) => (
                  <Badge
                    key={index}
                    overlap="circular"
                    badgeContent={
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveFile(file.key)}
                        sx={{
                          bgcolor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            bgcolor: "rgba(255, 255, 255, 1)",
                          },
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Tooltip title={file.name}>
                      <Box position="relative" display="inline-block">
                        <Avatar variant="rounded" src={file.url} alt={file.name} sx={{ margin: 1, width: 50, height: 50 }} />
                        {file.url.endsWith(".mp4") && (
                          <PlayCircleIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                          />
                        )}
                      </Box>
                    </Tooltip>
                  </Badge>
                ))}
              </Box>
            </Box>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileModal;
