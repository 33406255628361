// src/contexts/ChatKeyContext.js
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

const ChatKeyContext = createContext();
export const ChatKeyProvider = ({ children }) => {
  const [chatKey, setChatKeyInternal] = useState(null);
  useEffect(() => {
    const chatKeyLocalStorage = window.localStorage.getItem("chatKey");
    if (chatKeyLocalStorage && chatKeyLocalStorage !== '') {
      setChatKeyInternal(chatKeyLocalStorage);
    }
  }, []);
  const setChatKey = (value) => {
    setChatKeyInternal(value);
    window.localStorage.setItem("chatKey", value);
  }

  return (
    <ChatKeyContext.Provider value={{ chatKey, setChatKey }}>
      {children}
    </ChatKeyContext.Provider>
  );
};
export const useChatKey = () => {
  return useContext(ChatKeyContext);
};