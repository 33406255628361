export const tourIds = Object.freeze({
  questions: "questions-tour-id",
  messages: "messages-tour-id",
  claims: "claims-tour-id",
  chats: "chats-tour-id",
  notification: "notifications-tour-id",
  account: "account-tour-id",
  chatSidebar: "chat-sidebar-tour-id",
  activeChat: "active-chat-tour-id",
  theme: "theme-tour-id",
});
