export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    dashboard: "nav.dashboard",
    account: "nav.account",
    questions: "nav.questions",
    messages: "nav.messages",
    claims: "nav.claims",
    returns: "nav.returns",
    chats: "nav.chats",
  },
};
