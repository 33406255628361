import IconThunderbolt from "src/icons/icons/bolt";
import {
  useMediaQuery,
} from "@mui/material";

export const AnnouncementItemFull = ({logisticType}) => {
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down("desktop"));
  return (
      <div>
      { logisticType === "fulfillment" && 
      (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "12px",
            textAlign: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <span>
            <IconThunderbolt height="20px" width="20px" fill="#00a650" />
          </span>

          <b
            style={{
              color: "#00a650",
              fontWeight: "bolder",
              fontSize: 12,
              fontStyle: "italic",
            }}
          >
            FULL
          </b>
        </span>
      )}
    </div>
  );
}