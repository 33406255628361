import { createContext, useContext, useState, useEffect, useMemo } from "react";

export const SyncContext = createContext();

export function SyncProvider({ children }) {
  const [enabled, setEnabled] = useState(false);
  const [lastSync, setLastSync] = useState("");

  const contextValue = useMemo(
    () => ({
      enabled,
      setEnabled,
      lastSync,
      setLastSync,
    }),
    [enabled, lastSync]
  );

  return (
    <SyncContext.Provider value={contextValue}>{children}</SyncContext.Provider>
  );
}
