import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userParametersApi from "../api/user_parameters";

export const findAllParameters = createAsyncThunk(
  "user/findAllParameters",
  async ({ query, page, size }, thunkAPI) => {
    const parameters = await userParametersApi.findAllParameters({
      page,
      size,
      query,
    });
    return parameters;
  }
);

export const findAllUserParameters = createAsyncThunk(
  "user/findAllUserParameters",
  async ({ id, page, size }, thunkAPI) => {
    const parameters = await userParametersApi.findUserParameters({
      page,
      size,
      id,
    });

    return parameters;
  }
);

export const addUserParameter = createAsyncThunk(
  "user/addParameter",
  async (body, thunkAPI) => {
    const parameters = await userParametersApi.addUserParameter(body);
    thunkAPI.dispatch(
      findAllUserParameters({ id: body.userId, page: 0, size: 20 })
    );
    return parameters;
  }
);

export const delUserParameter = createAsyncThunk(
  "user/delParameter",
  async ({ id, userId }, thunkAPI) => {
    await userParametersApi.delUserParameter(id);
    thunkAPI.dispatch(
      findAllUserParameters({ id: parseInt(userId), page: 0, size: 20 })
    );
    return;
  }
);

const initialState = {
  parameters: [],
  user_parameters: [],
  paginate: {
    page: 0,
    size: 20,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "users_parameters",
  initialState,
  reducers: {
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllParameters.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findAllParameters.fulfilled, (state, { payload }) => {
        state.parameters = payload.rows;
      })
      .addCase(findAllParameters.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addUserParameter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addUserParameter.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addUserParameter.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(findAllUserParameters.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findAllUserParameters.fulfilled, (state, { payload }) => {
        state.user_parameters = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
      })
      .addCase(findAllUserParameters.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setPaginate },
} = slice;
