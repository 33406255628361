import { Toaster as HotToaster } from "react-hot-toast";
import { alpha, useTheme } from "@mui/material/styles";

export const Toaster = ({position = 'bottom-right'}) => {
  const theme = useTheme();
  return (
    <HotToaster
      position={position}
      toastOptions={{
        style: {
          backdropFilter: "blur(6px)",
          background: alpha(theme.palette.neutral[900], 0.8),
          color: theme.palette.common.white,
          boxShadow: theme.shadows[16],
        },
        success: {
          iconTheme: {
            primary: theme.palette.primary.main,
          },
        },
      }}
    />
  );
};
