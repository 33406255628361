import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "../api/user";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";

export const findAllUsers = createAsyncThunk(
  "user/findAllUsers",
  async ({ query, page, size }, thunkAPI) => {
    const users = await userApi.findAll({
      page,
      size,
      query,
    });
    return users;
  }
);

export const createOneUser = createAsyncThunk(
  "user/createOne",
  async (body, thunkAPI) => {
    const { name, email, password, userGroupId } = body;
    const user = await userApi.createOne({
      name,
      email,
      password,
      userGroupId,
    });

    if (user.status === 200 || user.email === email) {
      toast.success("Cadastro realizado com sucesso", {
        position: "bottom-right",
      });
      return { status : 200};
    } else {
      toast.error(user.message || "Não foi possível realizar o cadastro", {
        position: "bottom-right",
      });
    }
    return user;
  }
);

export const findOneUser = createAsyncThunk(
  "user/findOneUser",
  async (id, thunkAPI) => {
    const user = await userApi.findOne(id);

    return user;
  }
);

export const updateOneUser = createAsyncThunk(
  "users/updateOne",
  async ({ id, navigate, body }, thunkAPI) => {
    const user = await userApi.updateOne(id, body);
    if (user.id === id) {
      toast.success("Cadastro do usuário editado com sucesso", {
        position: "bottom-right",
      });
      // thunkAPI.findOneUser(user.id);
        return { status : 200};
    } else {
      toast.error("Não foi possível editar o cadastro", {
        position: "bottom-right",
      });
    }
    return user;
  }
);

export const deleteOne = createAsyncThunk(
  "user/deleteOne",
  async (id, thunkAPI) => {
     const response = await userApi.deleteOne(id);
     if (response.message === 'User removed successfully') {
       return { status: 200 };
     } else {
       toast.error("Não foi possível deletar o usuário", {
         position: "bottom-right",
       });
     }
     return response;
  }
 );

const initialState = {
  user: {},
  users: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findAllUsers.fulfilled, (state, { payload }) => {
        state.users = payload.rows;
        state.paginate = {
          page:
            payload.count > 0 ? parseInt(payload.page) : state.paginate.page,
          size:
            payload.count > 0 ? parseInt(payload.size) : state.paginate.size,
          count: payload.count > 0 ? parseInt(payload.count) : 0,
        };
        state.loading = false;
      })
      .addCase(findAllUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findOneUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
      })
      .addCase(findOneUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createOneUser.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createOneUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOneUser.fulfilled, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setUser, setPaginate },
} = slice;
