import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  IconButton,
  Typography,
  Box,
  Stack,
  Collapse,
  Tooltip,
  AvatarGroup,
  Divider,
  avatarClasses,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import styled from "styled-components";
import { neutral } from "src/theme/colors";
import ActionMenu from "src/components/action-menu";
import { failReasonStatus, formatCurrency, formatDate, getStatus } from "src/utils/utils-returns";
import { AnnouncementItemShippingInfo } from "src/sections/dashboard/chat/AnnouncementItem/AnnouncementItemShippingInfo";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ImageAvatar from "./avatar-row";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useNavigate } from "react-router";
import { useChatKey } from "src/contexts/chat-key-context";
import { api } from "src/services/api";

export const TitleWrapper = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

const MobileReturnCard = ({
  item,
  index,
  expandedRow,
  handleExpandClick,
  attachmentsExist,
  handleOpenAttachmentListModal,
  handleOpenDetailDrawer,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
}) => {
  
  const navigate = useNavigate();
  const { chatKey, setChatKey } = useChatKey();

  const handleReturnToClaim = () => {
    setChatKey(item.conversation_id);
    navigate(`/v1/claims?key=${item.conversation_id}`);
  };

  const HandleRedirectToMessages = async (item) => {
    try {
      const response = await api.get(`/v1/claims/redirectToMessages/${item.codPedido}/${item.claimId}`);
      
      const url = new URL(window.location.origin + response.data.redirect);
      const conversationId = url.searchParams.get('key');
  
      if (conversationId) {
        setChatKey(conversationId);
        navigate(response.data.redirect);
      } else {
        console.error("Conversation ID não encontrado na resposta.");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para as mensagens:", error);
    }
  };

  return (
    <Card key={index} sx={{ mb: 2, boxShadow: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "primary.main" }}
            variant="rounded"
            style={{ height: "40px" }}
          >
            <img
              src={item.thumbnail}
              alt="Thumbnail"
              style={{ height: "40px" }}
            />
          </Avatar>
        }
        action={
          <IconButton onClick={() => handleExpandClick(index)}>
            {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        title={item.title}
        titleTypographyProps={{
          variant: "body1",
          fontWeight: "bold",
        }}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          {`Status na plataforma: ${getStatus(item.statusMP)}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`Código do Pedido: ${item.codPedido}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`Data/Hora: ${formatDate(item.created_at)}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`Valor: ${formatCurrency(item.total_amount)}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`Qtde: ${item.quantity} unidade`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`SKU: ${item.sku}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`Comprador: ${item.customer}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <AvatarGroup
              max={2}
              sx={{
                [`& .${avatarClasses.root}`]: {
                  height: 20,
                  width: 20,
                },
              }}
            >
              <ImageAvatar item={item} />
            </AvatarGroup>
            {`Conta: ${item.account}`}
          </Box>
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <Typography variant="body2" color="textSecondary" width="100%">
            {`Status Weello:`}
            <br />
            <strong>{getStatus(item.statusWeello)}</strong>
          </Typography>
          <ActionMenu
            row={item}
            handleOpenDescriptionModal={handleOpenDescriptionModal}
            handleOpenStatusModal={handleOpenStatusModal}
            handleOpenInputFileModal={handleOpenInputFileModal}
            handleOpenReviewModal={handleOpenReviewModal}
          />
          <Tooltip title="Redirecionar para mensagens">
              <IconButton onClick={() =>HandleRedirectToMessages(item)}>
                <MessageOutlinedIcon />
              </IconButton>
            </Tooltip>
           <Tooltip title="Retornar para reclamação">
            <IconButton onClick={() => handleReturnToClaim(item)}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Tooltip>
          {attachmentsExist[item.id] && (
            <Tooltip title="Exibir anexos">
               <IconButton onClick={() => handleOpenAttachmentListModal(item)}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Detalhes">
            <IconButton
              onClick={() => handleOpenDetailDrawer(item)}
              sx={{
                padding: 0.5,
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              <InfoOutlined
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light" ? neutral[900] : neutral[50],
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
          <Box margin={1} sx={{ width: "100%" }}>
            <Typography variant="body2">
              <strong>Descrição</strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {item.description}
            </Typography>
            <Divider />
            <Typography variant="body2" marginTop={1}>
              <strong>Motivo que gerou a devolução</strong>
            </Typography>
            <Typography variant="body1">
              {item?.reason ? item?.reason : ""}
            </Typography>
            <Divider />
            {item.statusMP === "delivered" && (
              <>
                <Typography variant="body2" marginTop={1}>
                    <strong>Status da revisão</strong>
                </Typography>
                <Typography variant="body1">
                {item.review_status === "success"
                      ? `Revisão de devolução enviada ao marketplace por ${item.user_name} em ${formatDate(item.review_date)}.`
                      : (item.review_status === "claimed" || item.review_status === "failed" || item.review_status === "success") && item.user_name === null
                      ? "Revisão de devolução enviada pelo marketplace."
                      : item.review_status === "claimed" || item.review_status === "failed"
                      ? `Revisão de devolução (${failReasonStatus(item?.fail_reason)}) enviada ao marketplace por ${item.user_name} em ${formatDate(item.review_date)}.`
                      : "Ainda não revisado"}
                </Typography>
              </>
            )}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default MobileReturnCard;
