import { Box, Divider } from "@mui/material";
import * as React from "react";

import { TypesOrdering } from "./types-ordering";
import { RecentsOrdering } from "./recents-ordering";
import { AccountsOrdering } from "./accounts-ordering";

export const Sort = ({filter}) => {
  return (
    <>
      <Box
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TypesOrdering 
        filter={filter}
        />
        <AccountsOrdering />
        <RecentsOrdering />
      </Box>
      <Divider sx={{ margin: "0px 8px 8px 8px" }} />
    </>
  );
};
