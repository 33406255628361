import { httpConfig } from "src/config";
import { baseCoreUrl } from "./http";
import { api } from "src/services/api";
import { useUser } from "src/contexts/user-context";

const url = httpConfig.apiUrl[process.env.REACT_APP_ENV];

const AuthApi = {
  signIn: async (email, password) => {
    const response = await api.post(`/v1/auth/signin`, { email, password });
    return { status: response.status, ...response.data };
  },
  signUp: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  recoveryPassword: async (email) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/auth/recovery-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetPassword: async (token, password) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, password }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAuthenticated: async (accessToken) => {
    return new Promise((resolve, reject) => {
      fetch(`${url}/v1/auth/authenticated`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const newToken = res.headers.get("X-New-Token");
          if (newToken) {
            window.localStorage.setItem("accessToken", newToken);
          }
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default AuthApi;
