import { SvgIcon } from "@mui/material";
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import { useState, useEffect, useContext, useCallback } from "react";
import { SettingsContext } from "src/contexts/settings-context";
import { useSettings } from "src/hooks/use-settings";

export const useThemeSwitcher = () => {
  const { handleUpdate } = useSettings();
  const settings = useContext(SettingsContext);
  const [value, setValue] = useState(settings.paletteMode);

  useEffect(() => {
    setValue(settings.paletteMode);
  }, [settings.paletteMode]);

  const getIcon = () => {
    return value === 'light' 
      ? 
        <SvgIcon fontSize="small">
          <Moon01Icon />
        </SvgIcon>
      :
        <SvgIcon fontSize="small">
          <SunIcon />
        </SvgIcon>
  };

  const getTooltip = () => {
    const frase = 'Alterar para o tema';
    return value === 'light' ? `${frase} escuro` : `${frase} claro`;
  };

  const getMenuItem = () => {
    const frase = 'Tema';
    return value === 'light' ? `${frase} escuro` : `${frase} claro`;
  };

  const getAction = useCallback(() => {
    return value === 'light' ? 'dark' : 'light';
  }, [value]);

  const handleThemeChange = (newValue) => {
    handleUpdate({
      paletteMode: newValue,
    });
    setValue(newValue);
  };

  return {
    value,
    getAction,
    getIcon,
    getTooltip,
    handleThemeChange,
    getMenuItem
  };
};
