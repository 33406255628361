import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useChangePassword } from "src/contexts/change-password-context";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Save } from "react-feather";
import { LoadingButton } from "@mui/lab";
import { api } from "src/services/api";
import toast from "react-hot-toast";

export function ChangePassword() {
  const { showChangePassModal, setShowChangePassModal } = useChangePassword();
  const [showPassword, setShowPassword] = useState({
    password: false,
    currentPassword: false,
    repeatPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      current_password: "",
    },
    validationSchema: yup.object({
      current_password: yup.string().required("Senha obrigatória"),
      password: yup
        .string()
        .min(6, "A senha deve conter pelo menos 6 caracteres")
        .max(20, "A senha deve conter no máximo 20 caracteres")
        .matches(/(?=.*[0-9])/, "A senha deve conter pelo menos um número")
        .matches(
          /(?=.*[A-Z])/,
          "A senha deve ter pelo menos uma letra maiúscula"
        )
        .matches(
          /(?=.*[a-z])/,
          "A senha deve ter pelo menos uma letra minúscula"
        )
        .matches(
          /(?=.*[!@#$%^&*])/,
          "A senha deve conter pelo menos um caractere especial"
        )
        .required("Senha obrigatória"),
      confirm_password: yup
        .string()
        .required("Obrigatório confirmar a senha")
        .oneOf(
          [yup.ref("password"), null],
          "Digite igual sua senha, não pode haver diferença."
        ),
    }),
    onSubmit: handleChangePasswordUpdate,
  });

  async function handleChangePasswordUpdate(values, helpers) {
    setLoading(true);
    const { password, current_password } = values;

    try {
      await api.put(`/v3/user`, {
        password,
        current_password,
      });

      toast.success("Senha alterada com sucesso!", {
        position: "bottom-right",
      });
      setLoading(false);
      formik.resetForm();
      setShowChangePassModal(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Erro ao alterar senha", {
        position: "bottom-right",
      });
      setLoading(false);
      helpers.setStatus({ success: error.response?.status });
      helpers.setErrors({ current_password: error.response?.data?.message });
      helpers.setSubmitting(false);
    }
  }

  return (
    <Modal
      open={showChangePassModal}
      onClose={() => {
        formik.resetForm();
        setShowChangePassModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          "&:focus-visible": {
            outline: "none",
          },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            formik.resetForm();
            setShowChangePassModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign="center"
          sx={{ mt: 1 }}
          paddingBottom={5}
        >
          Alterar Senha
        </Typography>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid
            item
            gap={2}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Grid item width="100%">
              <TextField
                disabled={loading}
                fullWidth
                type={showPassword.currentPassword ? "text" : "password"}
                id="current_password"
                name="current_password"
                label="Senha atual"
                autoComplete="off"
                aria-autocomplete="none"
                value={
                  formik.values.current_password ||
                  formik.initialValues.current_password
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            currentPassword: !prevState.currentPassword,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.currentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  !!(
                    formik.touched.current_password &&
                    formik.errors.current_password
                  )
                }
                helperText={
                  formik.touched.current_password &&
                  formik.errors.current_password
                }
              />
            </Grid>
            <Grid item width="100%">
              <TextField
                disabled={loading}
                fullWidth
                type={showPassword.password ? "text" : "password"}
                id="password"
                name="password"
                label="Nova senha"
                autoComplete="off"
                aria-autocomplete="none"
                value={formik.values.password || formik.initialValues.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            password: !prevState.password,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item width="100%">
              <TextField
                disabled={loading}
                type={showPassword.repeatPassword ? "text" : "password"}
                fullWidth
                id="confirm_password"
                name="confirm_password"
                autoComplete="off"
                label="Repetir nova senha"
                value={
                  formik.values.confirm_password ||
                  formik.initialValues.confirm_password
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            repeatPassword: !prevState.repeatPassword,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.repeatPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  !!(
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                  )
                }
                helperText={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
              />
            </Grid>
            <Grid item width="100%">
              <Divider />
            </Grid>
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <LoadingButton
                disabled={loading}
                sx={{ ml: "auto", mr: 2, mb: 2 }}
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <Save />
                </SvgIcon>
                Salvar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
