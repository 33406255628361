import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userGroupsApi from "../api/user_groups";

export const findAllUsersGroups = createAsyncThunk(
  "user/findAllUsersGroups",
  async ({ query, page, size }, thunkAPI) => {
    const groups = await userGroupsApi.findAll({
      page,
      size,
      query,
    });
    return groups;
  }
);

export const findOneUserGroup = createAsyncThunk(
  "user/findOneUserGroup",
  async (id, thunkAPI) => {
    const group = await userGroupsApi.findOne(id);
    return group;
  }
);

export const updateOneUserGroup = createAsyncThunk(
  "users/updateOne",
  async ({ id, body }, thunkAPI) => {
    const group = await userGroupsApi.updateOne(id, body);
    return group;
  }
);

const initialState = {
  group: {},
  groups: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "users_groups",
  initialState,
  reducers: {
    setUserGroup: (state, action) => {
      state.user = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllUsersGroups.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findAllUsersGroups.fulfilled, (state, { payload }) => {
        state.groups = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllUsersGroups.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findOneUserGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneUserGroup.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.group = payload;
      })
      .addCase(findOneUserGroup.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setUserGroup, setPaginate },
} = slice;
