import * as React from "react";

export const Logo = (props) => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 392 205.79"
    style={{
      enableBackground: "new 0 0 392 205.79",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#31B7BC;}"}</style>
    <style>{".st1{fill:#AFCB27;}"}</style>
    <g>
      <g>
        <path
          className="st0"
          d="M41.57,159.58c-6.33-0.53-10.98-5.38-13.95-14.54c-2.97-9.17-4.98-18.83-6.04-28.99
			C20.52,105.9,20,98.38,20,93.5c0-19.39,0.66-35.42,1.98-48.08c1.05-10.42,3.89-15.76,8.51-16.03c2.5,0,4.35,1.19,5.54,3.56
			c0.4,2.11,0.59,6.2,0.59,12.27L36.03,74.9c0,19.52,1.91,40.3,5.74,62.33c0.26,1.19,0.4,2.04,0.4,2.57
			c0.66-1.45,2.44-10.49,5.34-27.11c5.54-33.5,12.13-50.26,19.79-50.26c3.56,0,7.29,3.83,11.18,11.48
			c3.89,7.65,7.15,15.43,9.79,23.35c3.03,9.5,7.06,18.73,12.07,27.7c2.11,4.35,3.96,6.53,5.54,6.53c0.66,0,1.19-1.52,1.58-4.55
			c2.37-12.93,3.96-25.75,4.75-38.49c0.79-12.73,1.19-20.48,1.19-23.25c0-4.09,0.26-10.22,0.79-18.4
			c0.79-13.19,2.57-19.79,5.34-19.79h0.79c2.77,0,4.16,2.77,4.16,8.31c0,6.46-0.2,14.31-0.59,23.55c0,43.4-2.97,70.9-8.9,82.51
			c-2.64,3.69-5.94,5.54-9.89,5.54c-8.84,0-17.41-9.5-25.72-28.49c-5.28-12.14-9.37-23.94-12.27-35.42l-0.2-1.39
			c-5.41,17.28-9.37,34.82-11.87,52.63l-1.78,9.3C51,153.45,47.11,158.79,41.57,159.58z"
        />
        <path
          className="st0"
          d="M154.55,145.73c-8.71,0-14.84-5.74-18.4-17.21c-0.66-2.77-0.99-5.47-0.99-8.11c0-8.05,2.77-15.5,8.31-22.36
			l2.18-2.77c5.14-5.14,9.83-7.72,14.05-7.72c6.99,0,11.34,3.69,13.06,11.08c0.53,1.58,0.79,3.17,0.79,4.75
			c0,7.26-3.23,12.73-9.7,16.42c-1.58,0.92-4.09,2.11-7.52,3.56c-3.43,1.45-5.15,2.84-5.15,4.16c0,0.79,0.62,1.88,1.88,3.27
			c1.25,1.38,2.47,2.08,3.66,2.08c1.85,0,3.76-0.4,5.74-1.19c3.3-0.92,5.74-1.39,7.32-1.39c2.9,0,4.35,1.06,4.35,3.17
			c0,2.38-1.91,4.75-5.74,7.12C163.38,144.02,158.77,145.73,154.55,145.73z M151.38,113.68c1.85,0,3.99-0.66,6.43-1.98
			c2.44-1.32,4.38-3.23,5.84-5.74c0.66-1.05,0.99-2.11,0.99-3.17c0-2.5-1.06-3.76-3.17-3.76c-0.66,0-1.72,0.56-3.17,1.68
			c-1.45,1.12-2.94,3.1-4.45,5.94c-1.52,2.84-2.28,5.05-2.28,6.63C151.58,113.55,151.51,113.68,151.38,113.68z"
        />
        <path
          className="st0"
          d="M199.46,145.73c-8.71,0-14.84-5.74-18.4-17.21c-0.66-2.77-0.99-5.47-0.99-8.11c0-8.05,2.77-15.5,8.31-22.36
			l2.18-2.77c5.14-5.14,9.83-7.72,14.05-7.72c6.99,0,11.34,3.69,13.06,11.08c0.53,1.58,0.79,3.17,0.79,4.75
			c0,7.26-3.23,12.73-9.7,16.42c-1.58,0.92-4.09,2.11-7.52,3.56c-3.43,1.45-5.15,2.84-5.15,4.16c0,0.79,0.62,1.88,1.88,3.27
			c1.25,1.38,2.47,2.08,3.66,2.08c1.85,0,3.76-0.4,5.74-1.19c3.3-0.92,5.74-1.39,7.32-1.39c2.9,0,4.35,1.06,4.35,3.17
			c0,2.38-1.91,4.75-5.74,7.12C208.3,144.02,203.68,145.73,199.46,145.73z M196.29,113.68c1.85,0,3.99-0.66,6.43-1.98
			c2.44-1.32,4.38-3.23,5.84-5.74c0.66-1.05,0.99-2.11,0.99-3.17c0-2.5-1.06-3.76-3.17-3.76c-0.66,0-1.72,0.56-3.17,1.68
			c-1.45,1.12-2.94,3.1-4.45,5.94c-1.52,2.84-2.28,5.05-2.28,6.63C196.49,113.55,196.42,113.68,196.29,113.68z"
        />
        <path
          className="st0"
          d="M244.18,146.13c-2.9,0-5.54-1.98-7.92-5.94c-5.54-8.44-8.31-25.99-8.31-52.63c0-29.94,4.16-44.92,12.47-44.92
			c3.96,0,5.94,2.38,5.94,7.12c-2.37,22.16-3.56,39.71-3.56,52.63c0,11.87,1.25,22.75,3.76,32.65c0.92,2.11,1.38,4.28,1.38,6.53
			C247.94,144.61,246.68,146.13,244.18,146.13z"
        />
        <path
          className="st0"
          d="M273.66,146.13c-2.9,0-5.54-1.98-7.92-5.94c-5.54-8.44-8.31-25.99-8.31-52.63c0-29.94,4.16-44.92,12.47-44.92
			c3.96,0,5.94,2.38,5.94,7.12c-2.37,22.16-3.56,39.71-3.56,52.63c0,11.87,1.25,22.75,3.76,32.65c0.92,2.11,1.38,4.28,1.38,6.53
			C277.42,144.61,276.16,146.13,273.66,146.13z"
        />
        <path
          className="st0"
          d="M306.9,145.54c-8.84,0-14.91-3.83-18.2-11.48c-1.98-4.75-2.97-9.83-2.97-15.23c0-13.19,4.55-22.62,13.65-28.3
			c3.43-1.71,6.53-2.57,9.3-2.57c2.5,0,5.28,1.02,8.31,3.07c3.03,2.05,5.44,5.67,7.22,10.88c1.78,5.21,2.67,10.32,2.67,15.33
			c0,3.96-0.53,7.92-1.58,11.87C322,139.13,315.87,144.61,306.9,145.54z M307.89,130.1c4.75-0.79,7.12-5.21,7.12-13.26
			c0-7.78-1.32-12.79-3.96-15.04c-0.53-0.66-1.12-0.99-1.78-0.99c-0.4,0-0.86,0.13-1.39,0.4c-3.96,2.64-5.94,8.05-5.94,16.22
			C301.95,125.88,303.93,130.1,307.89,130.1z"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="st1"
              d="M235.27,174.6c-0.6,0-1.31-0.23-1.86-0.64l0.02,0.02c-0.07-0.02-0.14-0.07-0.14-0.07
					c-0.05-0.02-0.02-0.05-0.05-0.07c-0.11,0.14-0.25,0.28-0.42,0.42c-0.26,0.22-0.65,0.19-0.86-0.08l-0.35-0.44l0.23-0.21
					l0.11-0.11c0.18-0.16,0.37-0.35,0.37-0.39v-10.22c0-0.33,0.27-0.6,0.6-0.6h0.02c0.33,0,0.6,0.27,0.6,0.6v2.89
					c0,0.41,0.4,0.7,0.79,0.57c0.31-0.11,0.63-0.17,0.94-0.17h0.41c0.68,0,1.29,0.22,1.88,0.65c0.02,0.01,0.04,0.03,0.06,0.04
					c0.54,0.46,0.89,1.02,1.03,1.61c0.09,0.53,0.16,1.24,0.16,1.96c0,0.74-0.07,1.45-0.16,1.98c-0.14,0.56-0.51,1.13-1.03,1.59
					c-0.02,0.02-0.04,0.03-0.06,0.05c-0.57,0.42-1.22,0.62-1.88,0.62H235.27z M235.68,173.41c0.76,0,1.53-0.61,1.76-1.29
					c0.01-0.03,0.02-0.06,0.02-0.1c0.09-0.48,0.13-1.12,0.13-1.65c0-0.56-0.04-1.2-0.13-1.66c-0.01-0.03-0.01-0.06-0.02-0.09
					c-0.23-0.67-1.01-1.31-1.76-1.31h-0.41c-0.67,0-1.37,0.6-1.67,1.17c-0.04,0.08-0.06,0.17-0.06,0.26v3.29
					c0,0.09,0.02,0.18,0.06,0.26c0.3,0.56,1,1.12,1.67,1.12H235.68z"
            />
            <path
              className="st1"
              d="M243.89,174.7c-0.71,0-1.36-0.25-1.91-0.69c-0.64-0.51-0.97-1.22-0.97-2v-5.41c0-0.33,0.27-0.6,0.6-0.6h0
					c0.33,0,0.6,0.27,0.6,0.6V172c0,0.97,0.97,1.5,1.68,1.5h0.14c0.69,0,1.61-0.48,1.68-1.4v-5.5c0-0.33,0.27-0.6,0.6-0.6h0.02
					c0.33,0,0.6,0.27,0.6,0.6v9.92c0,0.78-0.48,1.36-0.78,1.68c-0.11,0.13-0.22,0.26-0.33,0.38c-0.23,0.26-0.63,0.28-0.87,0.03
					l-0.01-0.01c-0.23-0.23-0.23-0.61,0-0.85l0.38-0.38c0.21-0.23,0.39-0.44,0.39-0.85v-1.39c0-0.4-0.39-0.69-0.77-0.57
					c-0.3,0.09-0.61,0.14-0.91,0.14H243.89z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M286.36,168.51c0,0.6-0.7,1.39-1.55,1.39h-8.33c0.11,0.35,0.27,0.69,0.48,1c0.2,0.32,0.99,1.33,2.99,1.33
					c0.55,0,1.1-0.1,1.65-0.29c0.22-0.08,0.43-0.16,0.62-0.26c0.24-0.12,1.19-0.34,1.75,0.43c0.55,0.77-0.09,1.67-0.47,1.87
					c-0.36,0.19-0.73,0.34-1.08,0.45c-0.81,0.26-1.67,0.39-2.57,0.39c-0.92,0-1.77-0.16-2.57-0.5c-0.8-0.34-1.49-0.79-2.08-1.37
					c-0.3-0.3-0.94-1.07-1.35-2c-0.38-0.86-0.53-1.89-0.53-2.32c0-0.89,0.17-1.72,0.51-2.51c0.34-0.78,0.8-1.46,1.4-2.04
					c0.59-0.58,1.28-1.04,2.07-1.37c0.79-0.34,1.64-0.51,2.54-0.51c0.86,0,1.68,0.16,2.47,0.48c0.79,0.32,1.49,0.76,2.09,1.32
					c0.61,0.56,1.09,1.24,1.44,2.02C286.18,166.83,286.36,167.57,286.36,168.51 M282.89,166.77c-0.24-0.46-0.56-0.85-0.97-1.17
					c-0.58-0.46-1.31-0.72-2.04-0.73c-0.79-0.01-1.5,0.22-2.12,0.71c-0.61,0.47-1.03,1.1-1.27,1.87h5.97
					C282.8,167.45,283.05,167.08,282.89,166.77"
            />
            <path
              className="st1"
              d="M313.46,171.22c0,0.59-0.14,1.12-0.42,1.59c-0.28,0.46-0.65,0.85-1.1,1.16c-0.45,0.31-0.96,0.48-1.53,0.64
					c-0.57,0.16-1.12,0.24-1.67,0.24c-0.93,0-1.79-0.17-2.58-0.51c-0.4-0.17-0.77-0.39-1.12-0.67c-0.57-0.45-0.59-1.31-0.07-1.83
					l0.09-0.09c0.45-0.45,1.16-0.46,1.68-0.09c0.2,0.15,0.41,0.27,0.64,0.38c0.41,0.19,0.86,0.29,1.32,0.3
					c0.5,0.01,0.92-0.08,1.25-0.26c0.32-0.17,0.48-0.43,0.48-0.78c0-0.36-0.23-0.66-0.68-0.9c-0.45-0.24-1.09-0.48-1.9-0.71
					c-0.27-0.08-1.4-0.5-2.25-1.11c-0.67-0.48-1.1-1.16-1.2-1.33c-0.21-0.4-0.32-0.86-0.32-1.39c0-0.58,0.14-1.1,0.41-1.56
					c0.13-0.22,0.61-0.97,1.6-1.51c1.04-0.56,2.42-0.55,2.72-0.55c1.34,0,2.51,0.36,3.5,1.07c0.63,0.46,0.71,1.36,0.16,1.9
					l-0.02,0.02c-0.46,0.45-1.17,0.48-1.7,0.11c-0.13-0.09-0.26-0.17-0.39-0.25c-0.45-0.25-0.94-0.37-1.48-0.37
					c-0.18,0-0.38,0.02-0.58,0.07c-0.2,0.05-0.39,0.12-0.57,0.21c-0.18,0.09-0.32,0.21-0.43,0.35c-0.11,0.15-0.17,0.31-0.17,0.49
					c0,0.32,0.21,0.59,0.65,0.83c0.43,0.23,1.05,0.47,1.85,0.7c0.52,0.15,1.02,0.35,1.48,0.58c0.47,0.23,0.88,0.53,1.23,0.84
					c0.35,0.31,0.62,0.71,0.83,1.1C313.35,170.27,313.46,170.72,313.46,171.22"
            />
            <path
              className="st1"
              d="M323.84,174.84L323.84,174.84v-1.25c-0.2,0.2-0.43,0.37-0.69,0.52c-0.26,0.15-0.54,0.28-0.83,0.38
					c-0.29,0.11-0.59,0.19-0.89,0.24c-0.3,0.06-0.59,0.08-0.86,0.08c-0.62,0-1.22-0.1-1.79-0.3c-0.57-0.2-1.08-0.5-1.51-0.91
					c-0.44-0.41-0.79-0.94-1.05-1.59c-0.26-0.65-0.39-1.42-0.39-2.31v-5.97c0-0.84,0.68-1.52,1.52-1.52c0.84,0,1.52,0.68,1.52,1.52
					v5.83c0,0.44,0.07,0.82,0.2,1.15c0.13,0.33,0.31,0.61,0.53,0.82c0.22,0.22,0.47,0.38,0.76,0.49c0.29,0.11,0.59,0.16,0.9,0.16
					c0.99,0,1.84-0.34,2.56-1.01v-7.43c0-0.84,0.68-1.52,1.52-1.52c0.84,0,1.52,0.68,1.52,1.52v7.73
					C326.88,173.16,325.52,174.84,323.84,174.84"
            />
            <path
              className="st1"
              d="M267.66,165.61l-1.44,4.35l-1.98-3.23c-0.47-0.8-0.99-1.37-1.83-1.37c-0.85,0-1.36,0.57-1.83,1.37
					l-1.91,3.22l-2.19-6.63c-0.22-0.74-0.94-1.22-1.72-1.12c-1.04,0.13-1.61,1.15-1.27,2.15l3.14,9.02c0.22,0.65,0.74,1.18,1.4,1.36
					c1.08,0.28,1.81-0.45,2.29-1.27l1.92-3.52c0.08-0.16,0.31-0.16,0.39,0l1.92,3.52c0.42,0.71,1,1.33,1.83,1.33
					c0.83,0,1.57-0.53,1.83-1.33l3.7-10.61C270.47,164.81,268.63,162.71,267.66,165.61"
            />
            <path
              className="st1"
              d="M272.58,160.66c0-0.87-0.7-1.57-1.57-1.57c-0.87,0-1.57,0.7-1.57,1.57s0.7,1.57,1.57,1.57
					C271.88,162.23,272.58,161.53,272.58,160.66"
            />
            <path
              className="st1"
              d="M301.71,168.51c0,0.6-0.7,1.39-1.55,1.39h-8.33c0.11,0.35,0.27,0.69,0.48,1c0.2,0.32,0.99,1.33,2.99,1.33
					c0.55,0,1.1-0.1,1.65-0.29c0.22-0.08,0.43-0.16,0.62-0.26c0.24-0.12,1.19-0.34,1.75,0.43c0.55,0.77-0.09,1.67-0.47,1.87
					c-0.36,0.19-0.73,0.34-1.08,0.45c-0.81,0.26-1.67,0.39-2.57,0.39c-0.92,0-1.77-0.16-2.57-0.5c-0.8-0.34-1.49-0.79-2.08-1.37
					c-0.3-0.3-0.94-1.07-1.35-2c-0.38-0.86-0.53-1.89-0.53-2.32c0-0.89,0.17-1.72,0.51-2.51c0.34-0.78,0.8-1.46,1.4-2.04
					c0.59-0.58,1.28-1.04,2.07-1.37c0.79-0.34,1.64-0.51,2.54-0.51c0.86,0,1.68,0.16,2.47,0.48c0.79,0.32,1.49,0.76,2.09,1.32
					c0.61,0.56,1.09,1.24,1.44,2.02C301.54,166.83,301.71,167.57,301.71,168.51 M298.24,166.77c-0.24-0.46-0.56-0.85-0.97-1.17
					c-0.58-0.46-1.31-0.72-2.04-0.73c-0.79-0.01-1.5,0.22-2.12,0.71c-0.61,0.47-1.03,1.1-1.27,1.87h5.97
					C298.16,167.45,298.4,167.08,298.24,166.77"
            />
          </g>
        </g>
      </g>
      <path
        className="st1"
        d="M346.55,37.57c-20.97,2.72-26.31,13.52-26.31,27.57c0,7.88,3.85,14.25,9.7,18.33
		c-0.67,2.12-1.96,3.95-3.37,5.42c-1.44,1.51-0.05,4.55,2.01,4.4c6.8-0.49,10.26-2.94,12.01-5.42c1.93,0.38,3.92,0.59,5.95,0.59
		c14.06,0,25.45-11.4,25.45-25.45S366.2,35.01,346.55,37.57z"
      />
    </g>
  </svg>
);
