import { appIdConfig, httpConfig } from "src/config";
import { baseCoreUrl } from "../http";


const STORAGE_KEY = "accessToken";

class MarkeplacesApi {
  constructor() {
    this.url = `${httpConfig.apiUrl[process.env.REACT_APP_ENV]}/v1/accounts`;
    this.headers = () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    });
    appIdConfig[process.env.REACT_APP_ENV];
  }
  getAccountsAll(account_id, params = {}) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(
        `${
          this.url
        }/${account_id}/application/${this.application()}/marketplaces?${new URLSearchParams(
          params
        )}`,
        { method: "GET", headers: this.headers() }
      );
      const data = await res.json();
      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }
  postAccountCreate(params) {
    return new Promise(async (resolve, reject) => {
      console.log("ENV process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
      const res = await fetch(`${this.url}`, {
        method: "POST",
        headers: this.headers(),
        body: JSON.stringify(params),
      });
      const data = await res.json();

      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }

  delAccountById(id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${id}`, {
        method: "DELETE",
        headers: this.headers(),
      });
      if (res.ok) {
        resolve();
      } else {
        reject();
      }
    });
  }

  updateAccount(params, id) {
    return new Promise(async (resolve, reject) => {
      const res = await fetch(`${this.url}/${id}`, {
        method: "UPDATE",
        headers: this.headers(),
        body: JSON.stringify(params),
      });
      const data = await res.json();

      if (res.ok) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  }
}

export default new MarkeplacesApi();
