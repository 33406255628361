import React from "react";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import toast from "react-hot-toast";
import { useUser } from "src/contexts/user-context";
import { s3 } from "src/utils/storage-provider";
import { RightDrawer } from "src/components/right-drawer";

const ReturnAttachmentList = ({
  open,
  onClose,
  attachments,
  onRemove,
  returnItem,
  selectedReturnId,
}) => {
  const { user } = useUser();
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));

  const handleDownload = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.click();
  };

  const handleRemove = async (index, file) => {
    const filePath = `tenants/${user.customer}/returnAttachments/${selectedReturnId}/${file.name}`;

    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: filePath,
      };
      await s3.send(new DeleteObjectCommand(params));
      toast.success("Arquivo removido com sucesso");
      onRemove(index);
    } catch (error) {
      console.error("Erro ao remover arquivo:", error);
      toast.error("Erro ao remover arquivo. Tente novamente.");
    }
  };

  return (
    <RightDrawer open={open} onClose={onClose} variant="persistent">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 16px",
        }}
      >
        <Typography variant="h6">Arquivos anexados</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: 2 }}>
        <Card sx={{ mb: 2, boxShadow: 3 }}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: "primary.main" }}
                variant="rounded"
                style={{
                  height: "50px",
                  width: "50px",
                  border: "1px solid #ddd",
                }}
              >
                <img
                  src={returnItem?.thumbnail}
                  alt="Thumbnail"
                  style={{ height: "50px", width: "50px" }}
                />
              </Avatar>
            }
            title={returnItem?.title}
            subheader={
              <>
                <Typography variant="body2" color="textSecondary">
                  {`Codigo do pedido: #${returnItem?.codPedido}`}
                </Typography>
              </>
            }
            titleTypographyProps={{
              variant: "body1",
              fontWeight: "bold",
            }}
          />
        </Card>
        {attachments.length > 0 ? (
          <Box mt={2}>
            <Typography variant="body2">Anexos:</Typography>
            <List>
              {attachments.map((file, index) => (
                <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                  <ListItemAvatar>
                    <Box position="relative" display="inline-block">
                      <Avatar
                        variant="rounded"
                        src={file.url}
                        alt={file.name}
                        style={{
                          height: "45px",
                          width: "45px",
                          border: "1px solid #ddd",
                        }}
                      />
                      {file.url.endsWith(".mp4") && (
                        <PlayCircleIcon
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        />
                      )}
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      wordBreak: "break-all",
                      paddingRight: "2rem",
                    }}
                  />
                  <Tooltip title="Baixar anexo">
                    <IconButton edge="end" onClick={() => handleDownload(file.url, file.name)}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover anexo">
                    <IconButton edge="end" onClick={() => handleRemove(index, file)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <Typography variant="body2">Nenhum anexo disponível</Typography>
        )}
      </Box>
    </RightDrawer>
  );
};

export default ReturnAttachmentList;
