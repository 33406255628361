import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActionMenu = ({
  row,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActionAvailable = (actionName, availableActions = []) => {
    let actions = [];

    if (typeof availableActions === "string") {
      try {
        actions = JSON.parse(availableActions);
      } catch (error) {
        console.error("Failed to parse availableActions:", error);
        return false;
      }
    } else if (Array.isArray(availableActions)) {
      actions = availableActions;
    }

    return actions.some((action) => action.action === actionName);
  };

  const hasReviewActions =
    isActionAvailable("return_review_ok", row?.available_actions) ||
    isActionAvailable("return_review_fail", row?.available_actions);

  const shouldShowReviewButton =
  row.statusMP === "delivered" && hasReviewActions && !row?.review_status;

  return (
    <>
      <Tooltip title="Ações">
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenDescriptionModal(row);
            handleClose();
          }}
        >
          {row.description ? "Editar descrição" : "Adicionar descrição"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenStatusModal(row);
            handleClose();
          }}
        >
          Editar status Weello
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenInputFileModal(row);
            handleClose();
          }}
        >
          Inserir anexo
        </MenuItem>

        {shouldShowReviewButton && (
          <MenuItem
            onClick={() => {
              handleOpenReviewModal(row);
              handleClose();
            }}
          >
            Revisar
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActionMenu;
