const QuestionIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 510.47 488.99"
      {...props}
    >
      <path
        d="M436.15,0.59H74.89c-41.05,0-74.32,33.28-74.32,74.32v251.59c0,36.33,26.21,66.66,60.71,73.06
            c1.36,0.25,2.76,0.36,4.15,0.37l34.55,0.37l0.3,88.72l136.62-88.21h199.27c41.04,0,74.32-33.27,74.32-74.32V74.91
            C510.47,33.87,477.2,0.59,436.15,0.59z M477.02,326.51c0,22.53-18.33,40.87-40.87,40.87H227.03l-97.82,68.5l-0.14-68.5H74.89
            c-22.54,0-40.87-18.33-40.87-40.87V74.91c0-22.54,18.33-40.87,40.87-40.87h361.27c22.53,0,40.87,18.33,40.87,40.87V326.51z"
      />
      <path d="M216.84,296.54c0,41.78,63.67,41.78,63.67,0C280.5,254.36,216.84,254.36,216.84,296.54z" />
      <path
        d="M248.67,67.72c-34.81,1.3-65.25,12.95-79.51,46.81c-7.17,17.02,4.68,35.95,22.93,35.27
            c0,0,29.56,2.31,33.28-18.29c2.06-11.4,9.79-17.64,25.07-18.21c21.15-0.79,45.6,4.53,46.6,31.19
            c0.78,20.77-19.93,24.32-37.65,34.69c-17.72,10.37-36.42,21.81-34.99,69.25l0.08,2.08c0,0,9.69,4.76,29.76,4.01
            c0.2-0.01,0.4-0.02,0.6-0.02c13.58-0.58,23.42-12.88,22.6-26.65c-0.34-5.7,0.44-10.99,3.77-13.43
            c19.28-14.24,64.51-22.97,62.63-73.16C342.92,89.29,296.77,65.92,248.67,67.72z"
      />
    </svg>
  );
};

export default QuestionIcon;
