import React from "react";
import styled from "styled-components";
import { Typography, Box as MuiBox } from "@mui/material";
import { failReasonStatus, formatDate } from "src/utils/utils-returns";

const ReviewStatusContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  padding: 16px;
  margin: 0.625rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ReviewStatus = ({ reviewStatus }) => {
  return (
    <ReviewStatusContainer>
      <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
        Revisão
      </Typography>
      {reviewStatus?.statusMP === "delivered" && (
        <>
          <Typography variant="body2" color="textSecondary">
            {reviewStatus?.review_status === "success"
              ? `Revisão de devolução enviada ao marketplace por ${
                  reviewStatus?.user_name
                } em ${formatDate(reviewStatus?.review_date)}.`
              : (reviewStatus?.review_status === "claimed" ||
                  reviewStatus?.review_status === "failed" ||
                  reviewStatus?.review_status === "success") &&
                reviewStatus?.user_name === null
              ? "Revisão de devolução enviada pelo marketplace."
              : reviewStatus?.review_status === "claimed" ||
                reviewStatus?.review_status === "failed"
              ? `Revisão de devolução (${failReasonStatus(
                  reviewStatus?.fail_reason
                )}) enviada ao marketplace por ${
                  reviewStatus?.user_name
                } em ${formatDate(reviewStatus?.review_date)}.`
              : "Ainda não revisado"}
          </Typography>
        </>
      )}
    </ReviewStatusContainer>
  );
};
