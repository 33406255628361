import { useEffect } from "react";
import { useLocation } from "react-router";
import { useChatKey } from "src/contexts/chat-key-context";
import { api } from "src/services/api";

const RedirectNotificationsMiddleware = ({ children }) => {
    const { setChatKey }  = useChatKey();
    const location = useLocation();

    useEffect(() => {
        const handleNotificationRedirection = async () => {
            const queryParams = new URLSearchParams(location.search);
            const chatKeyFromNotifications = queryParams.get('key');
            const source = queryParams.get('source');

            if (chatKeyFromNotifications && source && source === 'notification') {
                setChatKey(chatKeyFromNotifications);
                await api.patch(`/v1/conversation/set/read`, { conversationIds: [ chatKeyFromNotifications ] });

                queryParams.delete('source');
                const newUrl = `${location.pathname}?${queryParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }
        };

        handleNotificationRedirection();
    }, [location, setChatKey]);

    return <>{children}</>;
};

export default RedirectNotificationsMiddleware;
